import { addServerError } from 'actions/serverErrors'
import api from 'api'
import axios from 'axios'

import { utils } from 'helpers'

export const searchAssessmentFacilities = (data) => async (dispatch) => {
	const baseRequest = {
		filter: {
			address: data.parsedAddress.mergedAddress,
		},
		page: 0,
		size: 200,
		sort: 'type',
	}

	const rosreestrRequest = data.parsedAddress

	const onRequestError = (error, errorPlace) => {
		dispatch(
			addServerError({
				text: `Произошла ошибка при поиске объектов ${errorPlace}`,
				details: utils.getDetailsFromError(error),
			})
		)

		return null
	}

	return axios
		.all([
			api.facility
				.search(baseRequest)
				.then(({ data }) => data.content)
				.catch((error) => onRequestError(error, 'в базе')),
			api.facility
				.searchObjectsByAddress(rosreestrRequest)
				.then(({ data }) => data)
				.catch((error) => onRequestError(error, 'в росреестре')),
		])
		.then(([base, rosreestr]) => {
			return {
				base,
				rosreestr,
				facility: data,
			}
		})
}
