import { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import cx from 'clsx'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { utils } from 'helpers'

import { Dummy } from './Dummy'
import { CurrentTabContext } from './TabContext'
import Tabs from './Tabs'

import './DataBlock.scss'

export default class EntityContainer extends Component<any> {
	static propTypes = {
		title: PropTypes.any,
		body: PropTypes.any,
		children: PropTypes.any,
		pipeline: PropTypes.array,
		className: PropTypes.string,
		isShrinked: PropTypes.bool,
	}

	state = {
		currentTab: '',
	}

	setCurrentTab = (currentTab: any) => this.setState({ currentTab })

	static Pipeline = ({ pipeline }: any) =>
		!isEmpty(pipeline) && pipeline.find(({ active }: any) => active) ? (
			<div className='data-block__pipeline'>
				<div className='data-block__pipeline-row'>
					{pipeline.map(({ name, active }: any, i: number) => (
						<div key={i} className={active ? 'data-block__pipeline-item_active' : ''}>
							<span>{name}</span>
						</div>
					))}
				</div>
			</div>
		) : null

	static Head = (props: any) => (
		<div className='data-block__head'>
			<div className='data-block__columns'>{props.children}</div>
		</div>
	)

	static HeadColumn = ({ label, body, children = null, dummy = {} }: any) => {
		const actions = label.action && !Array.isArray(label.action) ? [label.action] : label.action

		const handleClick = (action: { disabled: boolean; onClick: () => void }) =>
			!action.disabled && action.onClick()

		return (
			<div className='data-block__column'>
				{!dummy.active ? (
					<>
						{label && (
							<div className='data-block__label'>
								<span>{label.value}</span>
								{actions &&
									actions.map((action: any, index: number) => {
										const Component = !action.fetching ? (
											<span
												key={index}
												onClick={() => handleClick(action)}
												className={cx('data-block__label-action', {
													active: !action.disabled,
												})}
											>
												{action.title}
											</span>
										) : (
											<span key={index} className='data-block__label-action loading'>
												<i className='zmdi zmdi-spinner' />
											</span>
										)

										return Component
									})}
							</div>
						)}
						<div className='data-block__body'>{body ?? children}</div>
					</>
				) : (
					<Dummy error={dummy.error} />
				)}
			</div>
		)
	}

	static TabColumns = ({ children, overflow = 'hidden' }: any) => (
		<div className='tab-columns' style={{ overflow }}>
			{children}
		</div>
	)

	static TabColumn = ({ children }: any) => <div className='tab-column'>{children}</div>

	static TabRow = (props: any) => {
		const {
			label,
			value,
			indent,
			breakAll,
			onClick,
			copied, // можно копировать
			ellipsis, // текст в одну строку, скрывать лишнее
			hideIfEmpty,
			actionToolTip,
			toolTipId,
			onlyIconClick,
			iconName = 'edit',
		} = props

		if (hideIfEmpty && !value) return null

		const rowClassName = cx('tab-column__row', {
			'tab-column__row_indent-md': !!indent,
			'tab-column__row-ellipsis': ellipsis,
		})

		const valueClassName = cx('tab-column__row-value', {
			'hovered-click': onClick && actionToolTip,
			'tab-column__row_break-all': !!breakAll,
		})

		const toolTip =
			actionToolTip && onClick
				? {
						'data-tip': actionToolTip,
						'data-for': toolTipId,
						'data-html': true,
				  }
				: {}

		const valueOnClick =
			onClick && !onlyIconClick
				? {
						onClick,
				  }
				: {}

		const iconOnClick =
			onClick && onlyIconClick
				? {
						onClick,
				  }
				: {}

		return (
			<div className={rowClassName}>
				{actionToolTip && onClick && (
					<ReactTooltip effect='solid' place='right' id={toolTipId} className='tooltip' />
				)}
				<span className='tab-column__row-label'>{label}</span>
				<div
					{...toolTip}
					{...valueOnClick}
					className={valueClassName}
					title={typeof value === 'string' ? value : ''}
				>
					{utils.defaultOr(value)}
					{onClick && <i {...iconOnClick} className={`zmdi zmdi-${iconName}`} />}
					{copied && (
						<div className='tab-column__row-copied'>
							<CopyToClipboard text={value ?? ''}>
								<i className='zmdi zmdi-copy' />
							</CopyToClipboard>
						</div>
					)}
				</div>
			</div>
		)
	}

	static Tabs = (props: any) => <Tabs {...props} />

	render() {
		return (
			<div
				className={clsx(
					'data-block',
					this.props.className,
					this.state.currentTab === 'История' && this.props.isShrinked && 'data-block--shrinked'
				)}
			>
				<CurrentTabContext.Provider
					value={{ currentTab: this.state.currentTab, setCurrentTab: this.setCurrentTab }}
				>
					{this.props.children}
				</CurrentTabContext.Provider>
			</div>
		)
	}
}
