import * as Sentry from '@sentry/react'

import { sentryUtils } from '@creditclubteam/helpers'

export default (error: any) => {
	Sentry.withScope((scope) => {
		scope.setExtras(sentryUtils.getExtra(error))
		Sentry.captureException(error)
	})
}
