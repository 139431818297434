/**
 * getAvailablePart
 *
 * @param entities Array with object
 * @param property Тame of the property that stores the desired value
 * @returns Available part
 */
export function getAvailablePart<T extends Record<string, number | unknown>>(
	entities: T[],
	property = 'part'
): number {
	const res = entities.reduce((acc, el) => {
		if (!(property in el)) return acc

		return acc - Number(el[property] || 0)
	}, 100)

	if (res < 0) return 0

	return res
}
