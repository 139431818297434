import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'

type State = {
	errors: Record<string, boolean>
	fetching: boolean
	isProcessUpdate: boolean
}

const initialState: State = {
	errors: {},
	fetching: false,
	isProcessUpdate: false,
}

const main = createSlice({
	name: 'brokerForm/main',
	initialState,
	reducers: {
		addValidError(state, { payload }: PayloadAction<string>) {
			state.errors[payload] = true
		},

		deleteValidError(state, { payload }: PayloadAction<string>) {
			state.errors[payload] = false
		},

		updateStatus(state, { payload }: PayloadAction<boolean>) {
			state.isProcessUpdate = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = main
export default main.reducer
