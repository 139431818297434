import { useCallback } from 'react'

import { Button } from '@creditclubteam/react-components'
import { FormLayout } from 'components/common'

interface Props {
	readOnly?: boolean

	submitButtonProps?: Partial<React.ComponentProps<typeof Button>>
	cancelButtonProps?: Partial<React.ComponentProps<typeof Button>>
	editButtonProps?: Partial<React.ComponentProps<typeof Button>>
}

const Controls = (props: React.PropsWithChildren<Props>) => {
	const { readOnly, submitButtonProps, cancelButtonProps, editButtonProps, children } = props

	const editDefaults: React.ComponentProps<typeof Button> = {
		title: editButtonProps?.title || 'Редактировать',
		mode: editButtonProps?.mode || 'min',
		buttonType: editButtonProps?.buttonType || 'button',
		attributes: {
			'data-test-id': 'controls-edit-button',
		} as Record<string, any>,
	}

	const cancelDefaults: React.ComponentProps<typeof Button> = {
		title: cancelButtonProps?.title || 'Отмена',
		theme: cancelButtonProps?.theme || 'text-red',
		buttonType: cancelButtonProps?.buttonType || 'button',
		attributes: {
			'data-test-id': 'controls-cancel-button',
		} as Record<string, any>,
	}

	const submitDefaults: React.ComponentProps<typeof Button> = {
		title: submitButtonProps?.title || 'Сохранить',
		buttonType: submitButtonProps?.buttonType || 'submit',
		attributes: {
			'data-test-id': 'controls-submit-button',
		} as Record<string, any>,
	}

	const handleEdit = useCallback(
		(event: any) => {
			if ('preventDefault' in event) event.preventDefault()

			if (editButtonProps?.onClick) editButtonProps.onClick(event)
		},
		[editButtonProps]
	)

	const editBtnProps: React.ComponentProps<typeof Button> = editButtonProps
		? { ...editButtonProps, ...editDefaults, onClick: handleEdit }
		: editDefaults

	const submitBtnProps: React.ComponentProps<typeof Button> = submitButtonProps
		? { ...submitButtonProps, ...submitDefaults }
		: submitDefaults

	const cancelBtnProps: React.ComponentProps<typeof Button> = cancelButtonProps
		? { ...cancelButtonProps, ...cancelDefaults }
		: cancelDefaults

	if (children) return <>{children}</>

	return (
		<div className={FormLayout.styles.controls}>
			{readOnly ? (
				<Button {...editBtnProps} />
			) : (
				<>
					<Button {...submitBtnProps} />
					<Button {...cancelBtnProps} />
				</>
			)}
		</div>
	)
}

export default Controls
