import { setModalShow } from 'actions/modalDialogs'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { actions } from 'reducers/broker/forms/broker'

import { utils } from 'helpers'

import { formatBrokerForm, formatEditBrokerForm } from '../helpers'
import { getOrganizationBrokers } from '../single'

import { checkBrokerForm, resetMain, setFetchingStatusMain, setProcessStatus } from './main'

export const setBrokerFormField = (field, value) => (dispatch) =>
	dispatch(actions.setField({ field, value }))
export const setEditBrokerForm = actions.edit
export const setAllAccessGroup = actions.setAllAccessGroup
export const setlAccessGroup = actions.setAccessGroup

export const resetBrokerForm = () => (dispatch) => {
	dispatch(actions.reset())
	dispatch(resetMain())
}

export const editBrokerByForm = (orgId, form, currAccessGroupIds) => (dispatch, getState) => {
	const allAccessGroupIds = getState().broker.single.accessGroup.map(({ id }) => id)
	const formattedForm = formatBrokerForm(orgId, form)

	return api.broker
		.updateEmployee(formattedForm, form.id)
		.then(async () => {
			await Promise.all([
				...allAccessGroupIds.map((accGroupId) =>
					currAccessGroupIds.includes(accGroupId)
						? api.broker.createAccesGroup(form.id, accGroupId)
						: api.broker.delAccesGroup(form.id, accGroupId)
				),
			])
			return formattedForm
		})
		.catch((err) => {
			dispatch(
				addServerError({
					text: 'Не удалось обновить брокера',
					details: utils.getDetailsFromError(err),
				})
			)

			return null
		})
}

export const createBrokerByForm = (orgId, form, accessGroupIds) => (dispatch, getState) => {
	const allAccessGroupIds = getState().broker.single.accessGroup.map(({ id }) => id)
	const formattedForm = formatBrokerForm(orgId, form)

	return api.broker
		.createEmployees(formattedForm)
		.then(async (r) => {
			if (accessGroupIds) {
				await Promise.all([
					...allAccessGroupIds.map((accGroupId) =>
						accessGroupIds.includes(accGroupId)
							? api.broker.createAccesGroup(r.data.id, accGroupId)
							: api.broker.delAccesGroup(r.data.id, accGroupId)
					),
				])
			}
			return r
		})
		.catch((err) => {
			dispatch(
				addServerError({
					text: 'Не удалось создать брокера',
					details: utils.getDetailsFromError(err),
				})
			)

			return null
		})
}

export const submitBrokerForm = (orgId, selectAccessGroup) => async (dispatch, getState) => {
	if (!dispatch(checkBrokerForm())) return

	const { broker } = getState().broker.forms

	dispatch(setProcessStatus(true))

	if (broker.id) {
		await dispatch(editBrokerByForm(orgId, broker, selectAccessGroup))
	} else {
		await dispatch(createBrokerByForm(orgId, broker, selectAccessGroup))
	}

	await dispatch(getOrganizationBrokers(orgId))

	dispatch(resetBrokerForm())
	dispatch(setModalShow(false, 'brokerForm'))

	dispatch(setProcessStatus(false))
}

export const getBroker = (brokerId) => (dispatch) => {
	dispatch(setFetchingStatusMain(true))
	dispatch(setModalShow(true, 'brokerForm'))

	api.broker
		.getEmployees(brokerId)
		.then(async ({ data }) => {
			const accessGroup = await api.broker.getEmployeesAccesGroupById(brokerId)
			dispatch(
				setEditBrokerForm({
					...formatEditBrokerForm(data),
					role: accessGroup.data,
				})
			)
		})
		.catch((error) => {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить брокера',
					details: utils.getDetailsFromError(error),
				})
			)
		})
		.finally(() => dispatch(setFetchingStatusMain(false)))
}
