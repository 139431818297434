import { setPagination, solveParamsPagination } from 'actions/listParameters'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { actions } from 'reducers/broker/list'

import { utils } from 'helpers'

export const resetBrokerList = actions.reset
export const brokerListChangeFilterParameter = actions.changeFilterParameter

export const getBrokers =
	(parameters = {}) =>
	(dispatch, getState) => {
		dispatch(actions.setFetchingStatus(true))

		const requestParameters = {
			...dispatch(solveParamsPagination('number', 'id,desc')),
			...parameters,
			filter: getState().broker.list.filter,
			sort: getState().broker.list.sort,
		}

		api.broker
			.search(requestParameters)
			.then(({ data }) => {
				const { totalElements, number, totalPages } = data

				dispatch(actions.setData(data.content))

				/** @namespace pageable.pageNumber */
				dispatch(
					setPagination({
						totalPages,
						totalElements,
						paged: true,
						currentPage: number,
					})
				)
			})
			.catch((error) => {
				dispatch(
					addServerError({
						text: 'Не удалось загрузить брокеров',
						details: utils.getDetailsFromError(error),
					})
				)
			})
			.finally(() => dispatch(actions.setFetchingStatus(false)))
	}
