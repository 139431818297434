import { combineReducers } from 'redux'

import forms from './forms'
import list from './list'
import single from './single'

export default combineReducers({
	list,
	forms,
	single,
})
