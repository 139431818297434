export const OWNER_TYPES = {
	APPLICATION: 'APPLICATION',
	LOAN: 'LOAN',
	PERSON: 'PERSON',
	ORGANIZATION: 'ORGANIZATION',
	ENTREPRENEUR: 'ENTREPRENEUR',
	FACILITY: 'FACILITY',
	BROKER: 'BROKER',
	DEPOSIT: 'DEPOSIT',
	DELIVERY: 'DELIVERY',
	ROSREESTR_REGISTRATION: 'ROSREESTR_REGISTRATION',
	REPRESENTATIVE: 'REPRESENTATIVE',
	LOAN_SELL_APPLICATION: 'LOAN_SELL_APPLICATION',
} as const
