import { actions } from 'reducers/broker/forms/main'

import { validBrokerForm, validOrganizationForm } from '../helpers'

export const resetMain = actions.reset
export const setFetchingStatusMain = actions.setFetchingStatus
export const setProcessStatus = actions.updateStatus

const validate = (validateSchema, value, field) => (dispatch) => {
	const fieldIsValid = validateSchema[field](value)

	if (fieldIsValid) {
		dispatch(actions.deleteValidError(field))
	} else {
		dispatch(actions.addValidError(field))
	}

	return fieldIsValid
}

const checkForm = (validateSchema, form) => (dispatch) => {
	let formIsValid = true

	validateSchema.requiredFields.forEach((field) => {
		const value = form[field]

		if (!dispatch(validate(validateSchema, value, field))) formIsValid = false
	})

	return formIsValid
}

export const fieldIsValid = (field) => (_, getState) => {
	const { errors } = getState().broker.forms.main

	return !errors[field]
}

export const validateOrganizationForm = (field) => (dispatch, getState) => {
	const value = getState().broker.forms.organization[field]

	return dispatch(validate(validOrganizationForm, value, field))
}

export const validateBrokerForm = (field) => (dispatch, getState) => {
	const value = getState().broker.forms.broker[field]

	return dispatch(validate(validBrokerForm, value, field))
}

export const checkBrokerForm = () => (dispatch, getState) => {
	const form = getState().broker.forms.broker

	return dispatch(checkForm(validBrokerForm, form))
}

export const checkOrganizationForm = () => (dispatch, getState) => {
	const form = getState().broker.forms.organization

	return dispatch(checkForm(validOrganizationForm, form))
}
