export default {
	brokers: ['brokers', 'broker'],
	agents: ['agents'],
	calendar: ['calendar'],
	loan: ['loan', 'loans'],
	calculator: ['calculator'],
	assessment: ['assessment'],
	deposits: ['deposits', 'deposit'],
	lead: ['lead', 'leads', 'investment-leads', 'new_lead'],
	facility: ['facility', 'facilities'],
	application: ['application', 'applications'],
	participants: ['participants', 'person', 'organization', 'entrepreneur'],
	deliveries: ['deliveries', 'delivery'],
	rosreestrRegistrations: ['rosreestr-registrations'],
	representatives: ['representatives'],
	loanSellApplications: ['loan-sell-applications'],
	accounting: ['accounting'],
} as const
