import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { findIndex, propEq } from 'rambda'
import { commonActions } from 'reducers/common'

export type AccessGroup = {
	id: string
	name: string
}

type State = {
	data: Record<string, any>
	fetching: boolean
	brokers: Array<any>
	accessGroup: AccessGroup[]
}

const initialState: State = {
	data: {},
	fetching: false,
	brokers: [],
	accessGroup: [],
}

const single = createSlice({
	name: 'broker/single',
	initialState,
	reducers: {
		setBrokers(state, { payload }: PayloadAction<any>) {
			state.brokers = payload
		},

		setNewBroker(state, { payload }: PayloadAction<any>) {
			state.brokers.push(payload)
		},

		updateBrokerById(state, { payload }: PayloadAction<any>) {
			const i = findIndex(propEq(payload?.id, 'id'))(state.brokers)
			state.brokers[i] = { ...state.brokers[i], ...payload }
		},

		setData(state, { payload }: PayloadAction<State['data']>) {
			state.data = payload
		},

		setAccessGroup(state, { payload }: PayloadAction<AccessGroup[]>) {
			state.accessGroup = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,

		reset: commonActions.getResetActionCreator(initialState),
	},
})

export const { actions } = single
export default single.reducer
