import { DefaultValues } from 'helpers/convertHelper'

export const TYPE = {
	CONTRACT_OF_SALE: 'Договор купли-продажи',
	DONATION_AGREEMENT: 'Договор дарения',
	BARTER_AGREEMENT: 'Договор мены',
	PRIVATIZATION_AGREEMENT: 'Договор приватизации',
	INHERITANCE: 'Наследование',
	EQUITY_AGREEMENT: 'Договор долевого участия',
	COURT_DECISION: 'Решение суда',
	ASSIGNMENT_OF_CLAIM: 'Уступка права требования',
} as const

type TYPE_KEYS = keyof typeof TYPE

export const PAYMENT_METHOD = {
	CASH: 'Передача наличными',
	CASHLESS: 'Безналичный перевод (на р/с)',
	OFFSETTING: 'Путем взаимозачета',
	EXCHANGE: 'Путем мены',
	OTHER: 'Иное',
} as const

type PAYMENT_METHOD_KEYS = keyof typeof PAYMENT_METHOD

export const ownershipDocument = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		b0022: model.null<boolean>(['b0022']),
		b0023: model.null<boolean>(['b0023']),
		b0024: model.null<boolean>(['b0024']),
		b0025: model.null<boolean>(['b0025']),
		b0026: model.null<boolean>(['b0026']),
		b0027: model.null<boolean>(['b0027']),
		b0028: model.null<boolean>(['b0028']),
		b0029: model.null<boolean>(['b0029']),
		b0031: model.null<boolean>(['b0031']),
		b0032: model.null<boolean>(['b0032']),
		b0037: model.null<boolean>(['b0037']),
		type: model.const(
			data.type,
			Object.keys(TYPE).reduce((acc, curr) => {
				acc[curr as TYPE_KEYS] = curr as TYPE_KEYS
				return acc
			}, {} as Record<TYPE_KEYS, TYPE_KEYS>)
		),
		typeRus: model.const(data.type, TYPE),
		date: model.string(['date']),
		paymentMethod: model.const(
			data.paymentMethod,
			Object.keys(PAYMENT_METHOD).reduce((acc, curr) => {
				acc[curr as PAYMENT_METHOD_KEYS] = curr as PAYMENT_METHOD_KEYS
				return acc
			}, {} as Record<PAYMENT_METHOD_KEYS, PAYMENT_METHOD_KEYS>)
		),
		paymentMethodRus: model.const(data.paymentMethod, PAYMENT_METHOD),
		amount: model.null<number>(['amount']),
		matchWithStatement: model.null<boolean>(['matchWithStatement']),
		transferAcceptanceCertificateSigningDate: model.string([
			'transferAcceptanceCertificateSigningDate',
		]),
	}
}
