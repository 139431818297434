/* eslint-disable react-hooks/rules-of-hooks */
import { ENTITY_TYPES } from 'const'
import {
	always,
	assoc,
	equals,
	ifElse,
	includes,
	mergeRight,
	pick,
	prop,
	reject,
} from 'ramda'

import { defaultToApi, defaultToArr, defaultToNull } from 'helpers/convertHelper'

export const rejectById = (v, data) =>
	data.filter(({ id }) => v !== id)
export const findIndexById = (v, data) => data.findIndex(({ id }) => v === id)

export const transformToRequest = ({ list, propsToPick = [] }) =>
	list.map(({ id, $$type, ...data }) =>
		Object.assign({ id, type: $$type }, pick(propsToPick, data))
	)

export const transformRosreestrParticipant = ({
	data: { id, $$type, director, ...data },
	propsToPick,
}) => {
	const result = Object.assign({ subject: { id, type: $$type } }, pick(propsToPick, data))

	if ($$type !== ENTITY_TYPES.ORGANIZATION) return result

	return Object.assign(
		result,
		director && { representative: { id: director.id, type: director.type } }
	)
}

export const transformToRosreestrRegistrationRequest = ({ list, propsToPick = [] }) =>
	list.map((data) => transformRosreestrParticipant({ data, propsToPick }))

export const getActionName = (actionTarget, type) => {
	type = type.toLowerCase()
	const entityType = type[0].toUpperCase() + type.slice(1)

	return `${actionTarget}${entityType}InList`
}

export const getChangableFieldsToApi = (entity) => ({
	part: defaultToApi(entity.part),
	roles: defaultToApi(entity.roles),
})

export const getChangableFieldsToStore = (entity) => ({
	part: defaultToNull(entity.part),
	roles: defaultToArr(entity.roles),
})

export const transformToOrganizationRequest = ({ sideParticipants, createdOrExistedEntity }) =>
	[].concat(
		transformToRequest({
			list: sideParticipants.list,
			propsToPick: sideParticipants.propsToPick,
		}).map((data) =>
			ifElse(
				includes('SIGNATORY'),
				() => {
					const newRoles = reject(equals('SIGNATORY'), data.roles)

					if (newRoles.length !== data.roles.length) {
						sideParticipants.addQueuedStoreUpdate({ id: data.id, value: { roles: newRoles } })
						sideParticipants.addQueuedNotice()
					}

					return assoc('roles', newRoles, data)
				},
				always(data)
			)(prop('roles', pick(createdOrExistedEntity.propsToPick, createdOrExistedEntity.data)))
		),
		mergeRight(
			{ id: createdOrExistedEntity.data.id, type: createdOrExistedEntity.data.$$type },
			pick(createdOrExistedEntity.propsToPick, getChangableFieldsToApi(createdOrExistedEntity.data))
		)
	)
