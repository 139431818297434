import { type PropsWithChildren, useEffect, useMemo, useState } from 'react'

import { PageLoading } from 'components/common/PageLoading'

import type { KcStatuses } from './ctx'
import { kcCtx } from './ctx'
import { keycloak } from './instance'
import { useLocalStorage } from 'usehooks-ts'
import { KC_LOCALSTORAGE_REFRESH_TOKEN_KEY, KC_LOCALSTORAGE_TOKEN_KEY } from 'client/keycloak/const'

let isInitialized = false

export const KeycloakProvider = ({ children }: PropsWithChildren<unknown>) => {
	const [status, setStatus] = useState<KcStatuses>('pending')
	const [authenticated, setAuthenticated] = useState(false)
	const [refreshToken, setRefreshToken] = useLocalStorage<string | undefined>(
		KC_LOCALSTORAGE_REFRESH_TOKEN_KEY,
		undefined
	)
	const [token, setToken] = useLocalStorage<string | undefined>(
		KC_LOCALSTORAGE_TOKEN_KEY,
		undefined
	)
	const value = useMemo(() => ({ kcStatus: status, authenticated }), [authenticated, status])

	keycloak.onAuthRefreshSuccess = () => {
		setToken(keycloak.token)
		setRefreshToken(keycloak.refreshToken)
	}

	useEffect(() => {
		const init = async () => {
			isInitialized = true

			try {
				await keycloak.init({
					onLoad: 'login-required',
					refreshToken,
					token,
					pkceMethod: 'S256',
					redirectUri: location.href,
					checkLoginIframe: false,
				})

				setToken(keycloak.token)
				setRefreshToken(keycloak.refreshToken)

				setAuthenticated(true)
				setStatus('success')
			} catch {
				setStatus('error')
			}
		}

		if (!keycloak.authenticated && !isInitialized) init()
	}, [refreshToken, setRefreshToken, setToken, token])

	if (!authenticated || status === 'pending') return <PageLoading useLogo fullVh />
	if (status === 'error') throw new Error()

	return <kcCtx.Provider value={value}>{children}</kcCtx.Provider>
}
