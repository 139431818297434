import { assessment } from './assessment'
import { form } from './form'
import { main } from './main'
import { oneCPledge } from './oneCPledge'
import { owner } from './owner'
import { ownershipDocument } from './ownershipDocument'

export const facility = {
	main,
	assessment,
	form,
	ownershipDocument,
	owner,
	oneCPledge,
}

export namespace Facility {
	export type Main = ReturnType<typeof main>
	export type Assessment = ReturnType<typeof assessment>
	export type OwnershipDocument = ReturnType<typeof ownershipDocument>
	export type Form = ReturnType<typeof form>
	export type Owner = ReturnType<typeof owner>
	export type OneCPledge = ReturnType<typeof oneCPledge>
}
