/* eslint-disable no-console */
import { HelmetProvider } from 'react-helmet-async'
import { Provider, ReactReduxContext } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { ErrorBoundary, init } from '@sentry/react'
import axios from 'axios'
import { browserHistory } from 'client/browserHistory'
import checkMicrosoftToken from 'client/checkMicrosoftToken'
import { KeycloakProvider } from 'client/keycloak'
import { keycloak } from 'client/keycloak/instance'
// import { UpdateNotification } from 'client/UpdateNotification'
import { sentryConfig } from 'client/sentry'
import CriticalError from 'components/Layout/CriticalError'
import { ConnectedRouter } from 'connected-react-router'
import { DataProvider } from 'features/data'
import { routes } from 'routes/routes'
import { preparedTheme } from 'scss/theme'
import { ThemeProvider } from 'styled-components'
import { ConfigureAxios } from '@creditclubteam/kit/legacy-helpers'

import { store } from './store'

const configureAxios = new ConfigureAxios()

init(sentryConfig)
configureAxios.init({ axios, keycloakInstance: keycloak })
axios.interceptors.request.use((config) => checkMicrosoftToken(store, config))

export const Root = () => {
	return (
		<ErrorBoundary fallback={CriticalError} onError={console.dir}>
			<ThemeProvider theme={preparedTheme}>
				<KeycloakProvider>
					<Provider store={store}>
						<HelmetProvider>
							<DataProvider>
								<ConnectedRouter history={browserHistory} context={ReactReduxContext}>
									{/* <UpdateNotification /> */}
									{renderRoutes(routes as any)}
								</ConnectedRouter>
							</DataProvider>
						</HelmetProvider>
					</Provider>
				</KeycloakProvider>
			</ThemeProvider>
		</ErrorBoundary>
	)
}