import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'
import type { Maybe } from 'types/util'

type State = {
	data: Array<any>
	fetching: boolean
	filter: {
		channel: Maybe<Array<string>>
		state: Maybe<Array<string>>
		type: Maybe<string>
		workers: Maybe<Array<string>>
		query: Maybe<string>
		minCreatedDate: Maybe<string>
		maxCreatedDate: Maybe<string>
	}
	sort: string
}

const initialState: State = {
	data: [],
	fetching: false,
	filter: {
		channel: null,
		state: null,
		type: null,
		workers: null,
		query: null,
		minCreatedDate: null,
		maxCreatedDate: null,
	},
	sort: 'createdAt,desc',
}

type ChangeFilterAction = PayloadAction<{
	field: keyof State['filter']
	value: any
}>

const list = createSlice({
	name: 'broker/list',
	initialState,
	reducers: {
		changeFilterParameter(state, { payload: { field, value } }: ChangeFilterAction) {
			state.filter[field] = value as any
		},

		resetData(state) {
			state.data = []
			state.fetching = false
		},

		resetFilter(state) {
			state.filter = initialState.filter
		},

		setData(state, { payload }: PayloadAction<State['data']>) {
			state.data = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = list
export default list.reducer
