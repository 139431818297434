import { ENTITY_TYPES } from 'const'

import { FACILITY_TYPES } from '@creditclubteam/helpers'
import { defaultToStr, DefaultValues } from 'helpers/convertHelper'

import { assessment } from './assessment'
import { form } from './form'
import { oneCPledge } from './oneCPledge'
import { owner } from './owner'
import { ownershipDocument } from './ownershipDocument'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		status: model.string<'RECORDED' | 'TEMPORARY' | 'UNCONFIRMED' | 'ANNULMENT' | 'EXCLUDED'>([
			'status',
		]),
		cadastralId: model.string(['cadastralId']),
		type: model.string<keyof typeof FACILITY_TYPES>(['type']),
		typeRus: defaultToStr(FACILITY_TYPES[data.type as keyof typeof FACILITY_TYPES]),
		assessment: assessment(data.assessment),
		form: form(data.form),
		address: model.null<Record<string, any>>(['address']),
		ownershipDocuments: model.array(['ownershipDocuments']).map(ownershipDocument),
		indicators: model.null<Record<string, any>>(['indicators']),
		oneCPledges: model.array(['oneCPledges']).map(oneCPledge),
		statementObjects: model.array<Record<string, any>[]>(['statementObjects']),
		owners: model.array(['owners']).map(owner),
		previousOwners: model.array(['previousOwners']).map(owner),
		$$type: ENTITY_TYPES.FACILITY,
	}
}
