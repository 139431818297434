import { getApplicationWorkers } from 'actions/application/workers'
import { fetchBrokersNames } from 'actions/common/broker'
import { setPagination, solveParamsPagination } from 'actions/listParameters'
import { getPipeline } from 'actions/pipeline'
import {
	searchBroker,
	searchBrokerEmployee,
	searchEntrepreneur,
	searchFacility,
	searchOrganization,
	searchPerson,
	setSearchResults,
} from 'actions/search'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import axios from 'axios'
import { keycloak } from 'client/keycloak/instance'
import { filter, flatten, isEmpty, map, pipe, prop } from 'ramda'
import { actions } from 'reducers/application/list'
import { applicationWorkersSelector } from 'reducers/application/workers'

import { utils } from 'helpers'
import { authorizedWorker } from 'helpers/authorizedWorker'

export const applicationListChangeFilterParameter = actions.setFilter
export const resetApplicationList = actions.reset

export const setDefaultApplicationFilter = () => (dispatch, getState) => {
	const pipeline = getState().pipeline.statuses.application
	const currentUser = applicationWorkersSelector.selectById(getState(), authorizedWorker.getId())
	const isUserLawyer = keycloak.tokenParsed?.groups.includes('/office-root/lawyers')

	if (isEmpty(pipeline)) return

	// все возможные статусы для фильтра заявок
	const allApplicationStatuses = pipeline.map((e) => e.id)

	// статусы которые отключаем по-умолчанию
	const disabledByDefaultStatuses = ['REFUSE', 'LOAN']

	// формируем массив активных статусов
	const defaultStatuses = allApplicationStatuses.filter((status) =>
		isUserLawyer
			? ['FIRST_APPROVAL', 'FIRST_ASSESSMENT'].includes(status)
			: !disabledByDefaultStatuses.includes(status)
	)

	if (defaultStatuses && !isEmpty(defaultStatuses)) {
		dispatch(actions.setFilter({ key: 'status', value: defaultStatuses }))
	}

	if (currentUser) {
		dispatch(actions.setFilter({ key: 'worker', value: [currentUser.workerId] }))
	}
}

export const getApplicationList =
	(parameters = {}) =>
	async (dispatch, getState) => {
		dispatch(actions.setFetchingStatus(true))

		const pipeline = getState().pipeline.statuses.application
		const workers = applicationWorkersSelector.selectIds(getState())

		if (isEmpty(workers)) {
			await dispatch(getApplicationWorkers())
		}

		if (isEmpty(pipeline)) {
			await dispatch(getPipeline('application'))

			dispatch(setDefaultApplicationFilter())
		}

		const requestParameters = {
			...dispatch(solveParamsPagination('number')),
			...parameters,
			filter: getState().application.list.filter,
		}

		try {
			const { data } = await api.application.search(requestParameters)

			const applicationWithProduct = await Promise.all(
				Array.from(
					data.content.map(async (application) => {
						if (!application.calculationV2.creditProgramId) return application

						const { data } = await api.calculation.program.get(
							application.calculationV2.creditProgramId
						)

						return {
							...application,
							product: data.product,
						}
					})
				)
			)

			dispatch(actions.setData(applicationWithProduct))
			dispatch(actions.setFetchingStatus(false))

			const { totalElements, number, totalPages } = data

			dispatch(
				setPagination({
					totalPages,
					totalElements,
					paged: true,
					currentPage: number,
				})
			)

			fetchBrokersNames({ data: data.content, key: 'application' })
		} catch (error) {
			dispatch(actions.setFetchingStatus(false))
			dispatch(
				addServerError({
					text: 'Не удалось загрузить заявки',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const searchApplicationByQuery = (query) => (dispatch) => {
	const requestOptions = {
		query,
		params: {
			size: 10,
			sort: 'desc',
			page: 0,
		},
	}

	return axios
		.all([
			dispatch(searchPerson(requestOptions)),
			dispatch(searchOrganization(requestOptions)),
			dispatch(searchEntrepreneur(requestOptions)),
			dispatch(searchFacility(requestOptions)),
			dispatch(searchBrokerEmployee(requestOptions)),
			dispatch(searchBroker(requestOptions)),
		])
		.then((data) => {
			const normalizeResponse = pipe(map(prop('content')), flatten, filter(Boolean))
			const participants = normalizeResponse(data)

			dispatch(setSearchResults('internal', participants))
		})
}
