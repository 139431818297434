import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'

export type AccessGroup = {
	id: string
	name: string
}

type State = {
	broker: Record<string, unknown>
	email: string
	enabled: boolean
	phone: string

	[key: string]: unknown
}

const initialState: State = {
	broker: {},
	email: '',
	enabled: true,
	phone: '',
}

const broker = createSlice({
	name: 'brokerForm/broker',
	initialState,
	reducers: {
		setField(
			state,
			{ payload: { field, value } }: PayloadAction<{ field: keyof State; value: unknown }>
		) {
			state[field] = value
		},

		edit(state, { payload }: PayloadAction<State>) {
			state = payload
			return state
		},

		reset: commonActions.reset(initialState),
	},
})

export const { actions } = broker
export default broker.reducer
