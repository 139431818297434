import { useMemo } from 'react'
import { PARTICIPANT_ROLES, ROLES_VALUES } from 'const'

import { date } from '@creditclubteam/helpers'
import type { SectionItem } from 'components/common/Section'
import formatText from 'helpers/getText'

const convert = {
	documents: (data: Record<string, any>[], facilityId?: string): SectionItem[] =>
		data.reduce((acc: SectionItem[], { documents, id, type }: Record<string, any>) => {
			if (type === 'FACILITY' && id !== facilityId) return acc

			documents.forEach(({ list }: { list: Record<string, any>[] }) =>
				list.forEach(({ id, title: label, signatures, createdAt }) => {
					const signaturesCount = signatures.length
					const hint: string = signaturesCount
						? `${signaturesCount} ${formatText(signaturesCount, [
								'подпись',
								'подписи',
								'подписей',
						  ])}`
						: 'Нет подписей'
					const hintTooltipText = signatures
						.map(({ signerName }: Record<string, any>) => signerName)
						.join(', ')
					date
					acc.push({
						id,
						label: `${label} от ${date.format(new Date(createdAt), { to: 'dd MMM yyyy' })}`,
						checked: !!signaturesCount,
						disabled: !signaturesCount,
						hint,
						hintTooltipText,
					})
				})
			)

			return acc
		}, []),
	declarants: (data: Record<string, any>[], excludeBorrowerRule: boolean): SectionItem[] =>
		data.reduce((acc: SectionItem[], { customerId, name: label, roles }: Record<string, any>) => {
			if (
				excludeBorrowerRule &&
				roles.includes(ROLES_VALUES.BORROWER) &&
				!roles.includes(ROLES_VALUES.OWNER)
			) {
				return acc
			}

			acc.push({
				id: customerId,
				label,
				checked: true,
				subText: roles
					.map((role: keyof typeof PARTICIPANT_ROLES) => PARTICIPANT_ROLES[role])
					.join(', '),
			})

			return acc
		}, []),
}

interface FormSections {
	documents: SectionItem[]
	declarants: SectionItem[]
	excludeBorrowerRule?: boolean
}

/** Возвращает начальные значения и секции для формы */
export const useSections = ({
	documents,
	declarants,
	excludeBorrowerRule = false,
	facilityId,
}: Record<string, any>): FormSections => {
	return useMemo(
		() => ({
			documents: convert.documents(documents, facilityId),
			declarants: convert.declarants(declarants, excludeBorrowerRule),
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[excludeBorrowerRule]
	)
}
