import moment from 'moment'
import { forEachObjIndexed } from 'ramda'

import { AddressBuilder, Person, phoneUtils } from '@creditclubteam/helpers'
import { utils } from 'helpers'

const getValue = (value) => value || null
const getString = (value) => value || ''

export const formatOrganizationForm = (form) => {
	const mainContact = {
		fullName: getValue(form.contact?.fullName),
		email: getValue(form.contactEmail),
		phone: form.contactPhone ? phoneUtils.unParse(form.contactPhone) : null,
	}

	const director = form.directorPhone
		? {
				fullName: getValue(form.director?.fullName),
				email: getValue(form.directorEmail),
				phone: phoneUtils.unParse(form.directorPhone),
		  }
		: {
				fullName: getValue(form.director?.fullName),
				email: getValue(form.directorEmail),
				phone: '',
		  }

	const bank = {
		bic: getValue(form.bank.bic),
		bankName: getValue(form.bank.bankName),
		correspondentAccount: getValue(form.bank.correspondentAccount),
		checkingAccount: form.checkingAccount
			? utils.checkingAccount.parse(form.checkingAccount)
			: null,
	}

	const workers = form.manager ? [{ id: form.manager, type: 'MANAGER' }] : []

	const organization = {
		// address
		city: getValue(form.actualAddress.city),
		region: getValue(form.actualAddress.region),
		legalAddress: getValue(form.organization.legalAddress),
		actualAddress: getValue(form.actualAddress.mergedAddress),
		mailingAddress: getValue(form.mailingAddress.mergedAddress),

		// info
		kpp: getValue(form.organization.kpp),
		ogrn: getValue(form.organization.ogrn),
		taxId: getValue(form.organization.inn),
		fullName: getValue(form.organization.name),
		shortName: getValue(form.organization.shortName),

		site: getValue(form.site),

		contractDate: form.contractDate ? moment(form.contractDate).format('YYYY-MM-DD') : null,
		nameForClient: form.nameForClient,
	}

	const info = {
		channel: getValue(form.channel),
		state: getValue(form.state),
		type: getValue(form.type),
	}

	return {
		...bank,
		...organization,
		...info,
		workers,
		mainContact,
		director,
	}
}

export const formatBrokerForm = (orgId, form) => {
	const formatedBrokerForm = {
		name: form.contact?.name || form.broker?.name || null,
		surname: form.contact?.surname || form.broker?.surname || null,
		patronymic: form.contact?.patronymic || form.broker?.patronymic || null,
		email: form.contact?.email || form.contactEmail || form.email || null,
		enabled: 'enabled' in form ? form.enabled : true,
		phone: form.contact?.phone
			? form.contact.phone
			: phoneUtils.unParse(form.contactPhone || form.phone),
	}

	if (!form.id) {
		formatedBrokerForm.organizationId = orgId
	}

	return formatedBrokerForm
}

export const formatEditBrokerForm = (data) => {
	return {
		id: data.id,
		email: data.email,
		enabled: data.enabled,
		phone: phoneUtils.parse(data.phone),
		role: data.role,
		broker: Person.from(data),
	}
}

export const formatEditOrganizationForm = (data) => {
	const bank = {
		bic: getString(data.bic),
		bankName: getString(data.bankName),
		correspondentAccount: getString(data.correspondentAccount),
	}

	const mainContact = {
		contact: {
			fullName: getString(data.mainContact?.fullName),
		},
		contactEmail: getString(data.mainContact?.email),
		contactPhone: phoneUtils.parse(getString(data.mainContact?.phone)),
	}

	const director = {
		director: {
			fullName: getString(data.director?.fullName),
		},
		directorEmail: getString(data.director?.email),
		directorPhone: phoneUtils.parse(getString(data.director?.phone)),
	}

	const address = {
		actualAddress: AddressBuilder.from({
			mergedAddress: getString(data.actualAddress),
			city: getString(data.city),
			region: getString(data.region),
		}),
		mailingAddress: AddressBuilder.from({
			mergedAddress: getString(data.mailingAddress),
		}),
	}

	const organization = {
		// info
		kpp: getString(data.kpp),
		inn: getString(data.taxId),
		name: getString(data.fullName),
		shortName: getString(data.shortName),
		legalAddress: getString(data.legalAddress),
	}

	const manager = getString(data.workers?.find((item) => item.type === 'MANAGER')?.id)

	const info = {
		id: data.id,
		type: getString(data.type),
		channel: getString(data.channel),
		state: getString(data.state),
		site: getString(data.site),
		nameForClient: getString(data.nameForClient),
		checkingAccount: getString(data.checkingAccount),
		contractDate: data.contractDate ? moment(data.contractDate)._d : '',
	}

	return {
		...address,
		...info,
		...mainContact,
		...director,
		manager,
		organization,
		bank,
		touch: false,
	}
}

export const validOrganizationForm = {
	requiredFields: ['nameForClient'],

	// common
	validPhone: (value) => (value ? phoneUtils.unParse(value).length === 10 : true),
	validEmail: (value) => (value ? utils.isEmail(value) : true),

	organization: ({ inn, name }) =>
		((inn || '').length === 10 || (inn || '').length === 12) && !!name,

	nameForClient: (value) => !!value,
	actualAddress: ({ mergedAddress, city }) => !!mergedAddress && !!city,
	contactEmail: (value) => validOrganizationForm.validEmail(value),
	contactPhone: (value) => validOrganizationForm.validPhone(value),
	directorEmail: (value) => validOrganizationForm.validEmail(value),
	directorPhone: (value) => validOrganizationForm.validPhone(value),
	bank: ({ bic: value }) => (value ? value.length === 9 : true),
	checkingAccount: (value) => (value ? value.replace(/\s+/g, '').length === 20 : true),
}

export const disableOrganizationForm = (form) => {
	const disabled = []
	const excludeFields = [
		'validPhone',
		'validEmail',
		'actualAddress',
		'organization',
		'requiredFields',
	]

	forEachObjIndexed((value, key) => {
		if (!excludeFields.includes(key)) {
			disabled.push(value(form[key]))
		}
	})(validOrganizationForm)

	if ('touch' in form) {
		disabled.push(form.touch)
	}

	return disabled.includes(false)
}

export const validBrokerForm = {
	requiredFields: ['broker', 'phone', 'email'],

	// common
	validPhone: (value) => (value ? phoneUtils.unParse(value).length === 10 : false),
	validEmail: (value) => (value ? utils.isEmail(value) : false),

	// fields
	broker: ({ fullName = '' }) => fullName.length > 5,

	phone: (value) => {
		return validBrokerForm.validPhone(value)
	},
	email: (value) => {
		return validBrokerForm.validEmail(value)
	},
}

export const disableBrokerForm = (form) => {
	const disabled = []
	const excludeFields = ['validPhone', 'validEmail', 'requiredFields']

	forEachObjIndexed((value, key) => {
		if (!excludeFields.includes(key)) {
			disabled.push(value(form[key]))
		}
	})(validBrokerForm)

	return disabled.includes(false)
}
