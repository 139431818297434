import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'

import { AddressBuilder } from '@creditclubteam/helpers'

type State = {
	bank: Record<string, unknown>
	contact: Record<string, unknown>
	director: Record<string, unknown>
	checkingAccount: string
	contactEmail: string
	contactPhone: string
	directorEmail: string
	directorPhone: string
	contractDate: null
	actualAddress: AddressBuilder
	mailingAddress: AddressBuilder
	nameForClient: string
	organization: Record<string, unknown>
	site: string
	channel: string
	state: string

	[key: string]: unknown
}

const initialState: State = {
	bank: {},
	contact: {},
	director: {},
	checkingAccount: '',
	contactEmail: '',
	contactPhone: '',
	directorEmail: '',
	directorPhone: '',
	contractDate: null,
	actualAddress: AddressBuilder.createEmpty(),
	mailingAddress: AddressBuilder.createEmpty(),
	nameForClient: '',
	organization: {},
	site: '',
	channel: '',
	state: '',
}

const organization = createSlice({
	name: 'broker/organization',
	initialState,
	reducers: {
		setField(
			state,
			{ payload }: PayloadAction<{ field: string; value: string | Record<string, unknown> }>
		) {
			state[payload.field] = payload.value
		},

		edit(state, { payload }: PayloadAction<State>) {
			state = payload
			return state
		},

		reset: commonActions.reset(initialState),
	},
})

export const { actions } = organization
export default organization.reducer
