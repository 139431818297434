import { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { initBreadcrumbs } from 'actions/breadcrumbs'
import { logout } from 'actions/login'
import { resetsByLocation } from 'actions/resetsByLocation'
import { getWorkers } from 'actions/worker'
import CalendarEventForm from 'components/CalendarEventForm'
import ServerErrors from 'components/ServerErrors'
import { push } from 'connected-react-router'
import moment from 'moment'
import PropTypes from 'prop-types'
import { workerSelector } from 'reducers/worker'

import { Loading } from 'components/common'
import FilesViewer from 'components/common/FilesViewer'
import { notifications, utils } from 'helpers'
import { authorizedWorker } from 'helpers/authorizedWorker'

import CriticalError from './CriticalError'
import Navigation from './Navigation'
import Notices from './Notices'
import UploadProgress from './UploadProgress'

import './Layout.scss'

class Layout extends Component {
	state = {
		isMobile: utils.isMobileWidth(),
	}

	static propTypes = {
		route: PropTypes.object.isRequired,
		match: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		breadcrumbs: PropTypes.object.isRequired,

		// user
		user: PropTypes.object,
		fetching: PropTypes.bool,

		push: PropTypes.func.isRequired,
		logout: PropTypes.func.isRequired,
		getWorkers: PropTypes.func.isRequired,
		initBreadcrumbs: PropTypes.func.isRequired,
		resetsByLocation: PropTypes.func.isRequired,
	}

	prevPath = ''

	async componentDidMount() {
		const {
			getWorkers,
			initBreadcrumbs,
			route: { routes },
		} = this.props

		notifications.init()

		window.addEventListener('resize', this.handleResize)

		document.body.style.backgroundColor = '#fff'

		initBreadcrumbs(routes)

		await getWorkers()

		this.prevPath = window.location.pathname
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}

	getSnapshotBeforeUpdate(prevProps) {
		const {
			initBreadcrumbs,
			resetsByLocation,
			route: { routes },
			location: { pathname },
		} = this.props

		const prevPath = prevProps.location.pathname.slice(1).split('/')[0]

		if (prevProps.location.pathname !== pathname) {
			// this.updateServiceWorkerIfNeeded();

			// если пользователь уходит на 1-й уровень роута
			if (pathname.slice(1).split('/').length === 1) {
				initBreadcrumbs(routes)
			}
			this.prevPath === pathname
			resetsByLocation(prevPath)
		}

		return null
	}

	componentDidUpdate() {
		/** DONT TOUCH ME */
	}

	renderBuildDateIfNeeded() {
		// if (window.__env__.BUILD_MODE === 'development') {
		// 	const date = moment(BUILD_DATE).format('DD.MM.YYYY HH:mm')

		// 	return <div className='build-date'>{date}</div>
		// }

		return null
	}

	renderLoadingHeader() {
		return (
			<div className='loading-header'>
				<div className='loading-header__wrap'>
					<div className='loading-header__hint' />
					<div className='loading-header__hint' />
					<div className='loading-header__hint' />
					<div className='loading-header__hint' />
					<div className='loading-header__hint' />
				</div>
			</div>
		)
	}

	render() {
		const {
			route,
			history,
			location,
			logout,
			fetching,
			user,
			route: { routes },
		} = this.props

		const navigationOptions = { history, location, logout, user, routes }

		// Получаем полную высоту меню для определения высоты wrap
		const menuNode = document.getElementById('navMenu')
		const menuNodeHeight = menuNode && utils.getFillHeightNodeElement(menuNode)

		const wrapStyle = {
			height: `calc(100% - ${menuNodeHeight}px)`,
		}

		return (
			<div>
				<Notices />
				<FilesViewer />
				<ServerErrors />
				<UploadProgress />
				<CalendarEventForm />
				{fetching ? (
					<>
						{this.renderLoadingHeader()}
						<Loading />
					</>
				) : user ? (
					<div className='main'>
						<Navigation {...navigationOptions} />
						<div className='wrap' style={wrapStyle}>
							<Suspense fallback={<Loading />}>{renderRoutes(route.routes)}</Suspense>
						</div>
						{this.renderBuildDateIfNeeded()}
					</div>
				) : (
					<CriticalError />
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	user: workerSelector.selectById(state, authorizedWorker.getId()),
	fetching: state.worker.fetching,
	breadcrumbs: state.breadcrumbs,
})

const mapDispatchToProps = {
	push,
	logout,
	initBreadcrumbs,
	resetsByLocation,
	getWorkers,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
