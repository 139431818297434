import { hasActiveCertificate } from 'actions/document'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import axios from 'axios'
import { ROLES_VALUES, ROSREESTR_ENCUMBRANCE_STATUSES } from 'const'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import moment from 'moment'
import { nanoid } from 'nanoid'
import { clone, flatten, head, isEmpty, omit, sort } from 'ramda'
import {
	addEncumbrance,
	appendMortgages,
	appendRosreestrMortgages,
	reset,
	setDataIn,
	setMortgages,
	setResponseSign,
	setRosreestrMortgages,
	updateEncumbrance,
	updateMortgage,
	updateStatementStatus,
} from 'reducers/rosreestr'

import { fileUtils } from '@creditclubteam/helpers'
import { FACILITY_TYPES } from '@creditclubteam/helpers'
import { utils } from 'helpers'

export const resetRosreestr = reset

export const searchRosreestrMortgages =
	({ loanId, facilityId }) =>
	async (dispatch) => {
		async function fetchSignedStatus(res) {
			const copy = clone(res)

			for (const rosreestrMortgage of copy) {
				const statements = rosreestrMortgage.konturInfo.statements

				for (const statement of statements) {
					const { data } = await api.document.get(statement.document.documentId)

					const signature = head(data.signatures)

					statement.document.signed = signature.signedAt ? true : false
				}
			}

			const isAllSigned = copy.every((rosreestrMortgage) =>
				rosreestrMortgage.konturInfo.statements.every((statement) => statement.document.signed)
			)

			copy.forEach((rosreestrMortgage) => (rosreestrMortgage.isAllSigned = isAllSigned))

			return copy
		}

		try {
			const { data } = await api.rosreestr.searchMortgages({ filter: { loanId, facilityId } })

			const withSignedStatuses = await fetchSignedStatus(data.content)

			dispatch(setRosreestrMortgages(withSignedStatuses))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить закладные',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const fetchMortgageResponseSignStatuses =
	({ documentId, declarantId }) =>
	async (dispatch) => {
		try {
			const { data: document } = await api.document.get(documentId)

			const findedSignature = document.signatures.find(
				(signature) => signature?.signer?.subject?.id === declarantId
			)

			dispatch(
				setResponseSign({
					key: documentId,
					data: {
						declarantId,
						signed: !!findedSignature?.signedAt,
						hasSignatureInProgress: findedSignature ? !findedSignature?.signedAt : false,
					},
				})
			)
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить данные для закладной',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const mortgageResponseSign = (signData) => async (dispatch) => {
	try {
		const { data } = await api.document.addSigner(signData)

		const document = head(data)

		const findedSignature = document.signatures.find(
			(signature) => signature.signer?.subject?.id === signData.signer.subject?.id
		)

		dispatch(
			setResponseSign({
				key: document.id,
				data: {
					declarantId: signData.declarantId,
					signed: !!findedSignature?.signedAt,
					hasSignatureInProgress: findedSignature ? !findedSignature?.signedAt : false,
				},
			})
		)
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось подписать документы',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}

export const createRosreestrMortgage = (rosreestrData) => async (dispatch) => {
	try {
		const { data } = await api.rosreestr.createMortgage(rosreestrData)

		dispatch(appendRosreestrMortgages(data))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось создать закладную',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}

// Добавляет данные о документах в mortgage
export const getMortgageDocumentsSigned = () => async (dispatch, getState) => {
	const mortgages = getState().rosreestr.mortgages.data

	// TODO Переименовать функцию ниже
	/**
	 * appendSigned
	 *
	 * Задача функции, получить необходимые данные, для mortgages.
	 * Функция содержит несколько вложенных итераций
	 *
	 * #1 - Перебор каждой закладной, в массие mortgages
	 * #2 - Перебор массива statements, который находится в каждой закладной
	 * #3 - Перебор массива с документами, которые используются mortgage
	 * #4 - Каждый документ содержит массив сигнатур. Итерируем их
	 */
	async function appendSigned(mortgages) {
		const copy = clone(mortgages)

		for (const [index, mortgage] of copy.entries()) {
			// #1
			const ids = mortgage.statements.map((el) => el.document.id)

			const promises = await Promise.allSettled(ids.map((id) => api.document.get(id)))

			const documents = promises
				.filter(({ status }) => status === 'fulfilled')
				.map(({ value }) => value.data)

			copy[index].statements.forEach((statement) => {
				// #2
				documents.forEach((document) => {
					// #3
					if (document.id === statement.document.id) {
						document.signatures.forEach((signature) => {
							// #4
							if (signature.signer.customerId === statement.declarant.id) {
								statement.document.signed = signature.signedAt ? true : false
								statement.document.title = document.title
								statement.document.categoryId = document.categoryId
								statement.document.date = moment(document.createdAt).format('DD.MM.YYYY')
							}
						})
					}
				})
			})

			const allSigned = copy[index].statements.every((el) => el.document.signed)

			copy[index].allSigned = allSigned
		}

		return copy
	}

	function appendNames(mortgages, participants) {
		const copy = clone(mortgages)

		copy.forEach((mortgage) => {
			mortgage.statements.forEach((statement) => {
				const findedParticipant = participants.find(
					(participant) => participant.customerId === statement.declarant.id
				)

				if (findedParticipant) {
					statement.declarant.name = findedParticipant.name

					if (findedParticipant.customerType === 'ORGANIZATION') {
						statement.declarant.representativeId = findedParticipant.director.id
					}
				}
			})
		})

		return copy
	}

	const mortgagesWithDocumentsData = await appendSigned(mortgages)
	const mortgagesWithDeclarantsData = appendNames(
		mortgagesWithDocumentsData,
		getState().rosreestr.participants
	)

	dispatch(setMortgages(mortgagesWithDeclarantsData))
}

export const initMortgages = (loanId) => async (dispatch, getState) => {
	try {
		const mortgages = getState().rosreestr.mortgages.data

		if (isEmpty(mortgages)) {
			const { data } = await api.mortgage.search({ filter: { loanId } })
			dispatch(setMortgages(data.content))
		}

		dispatch(getMortgageDocumentsSigned())
	} catch (e) {
		dispatch(
			addServerError({
				text: 'Не удалось загрузить закладные',
				details: utils.getDetailsFromError(e),
			})
		)
	}
}

export const createMortgage = (mortgageData) => async (dispatch) => {
	try {
		await axios.all(
			mortgageData.declarants
				.filter(({ type }) => type === 'ORGANIZATION')
				.map(({ id }) => api.organization.syncSignatureOfRepresentative(id))
		)

		const { data } = await api.mortgage.create(mortgageData)

		const promises = data.statements.map((el) => {
			const col = {
				ENTERPRENEUR: () => api.entrepreneur.get(el.declarant.id),
				ORGANIZATION: () => api.organization.get(el.declarant.id),
				PERSON: () => api.person.get(el.declarant.id),
			}

			return col[el.declarant.type]
		})

		const result = await Promise.allSettled(promises.map((f) => f()))

		result.forEach((el) => {
			if (el.status === 'fulfilled') {
				const i = data.statements.findIndex(
					(statement) => statement.declarant.id === el.value.data.id
				)

				if (el.value.data.$$type === 'ORGANIZATION' || el.value.data.$$type === 'ENTERPRENEUR') {
					data.statements[i].declarant.name = el.value.data.name
				}

				if (el.value.data.$$type === 'PERSON') {
					data.statements[
						i
					].declarant.name = `${el.value.data.name} ${el.value.data.surname} ${el.value.data.patronymic}`
				}
			}
		})

		dispatch(appendMortgages(result))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось создать закладную',
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const sendMortgage = (id) => async (dispatch) => {
	try {
		const { status, data } = await api.mortgage.sendToRosreestr(id)

		if (status === 200) {
			dispatch(updateMortgage(data))
		}
	} catch (e) {
		dispatch(
			addServerError({
				text: 'Не удалось отправить закладную',
				details: utils.getDetailsFromError(e),
			})
		)
	}
}

export const sendRosreestrMortgage = (id) => async (dispatch) => {
	try {
		await api.rosreestr.sendRosreestrMortgage(id)
	} catch (e) {
		dispatch(
			addServerError({
				text: 'Не удалось отправить закладную',
				details: utils.getDetailsFromError(e),
			})
		)
	}
}

// const hasActiveCertificateFromSignature = (signature) => {
// 	if (utils.take(signature, 'certificate')) {
// 		const { activeFrom, activeTo } = signature.certificate
//
// 		return moment().isBetween(moment(activeFrom, 'YYYY-MM-DD'), moment(activeTo, 'YYYY-MM-DD'))
// 	}
// }

const formatSignature = (signature, participants) => {
	if (signature.signer.customerType === 'EXTERNAL') {
		return {
			customerType: signature.signer.subject.type,
			signerName: 'Внешний источник',
		}
	} else {
		const participant = participants.find((e) => e.customerId === signature.signer.subject.id)

		return {
			customerType: signature.signer.subject.type,
			signerName: participant ? participant.name : 'Неизвестно',
		}
	}
}

const getFacilityInfo = (item) => {
	const title = []

	const house = utils.join([FACILITY_TYPES[item.type], item.area && `${item.area} м²`], ' ')
	const cadaster = utils.isCadaster(item.cadastralId) ? item.cadastralId : null

	title.push(...[house, cadaster].filter(Boolean))

	return title
}

const convertEncumbrances = (encumbrances, facilities) => {
	const result = []

	facilities.forEach((facility) => {
		// // снятие обременения
		// const encumbranceCessation = encumbrances.find(
		// 	(e) => e.facility.facilityId === facility.id && e.type === ENCUMBRANCE_TYPES.CESSATION
		// )
		// // наложение обременения
		// const encumbranceImposition = encumbrances.find(
		// 	(e) => e.facility.facilityId === facility.id && e.type === ENCUMBRANCE_TYPES.IMPOSITION
		// )
		const encumbrancesByFacility = encumbrances.filter((e) => e.facility.facilityId === facility.id)

		if (encumbrancesByFacility.length) {
			encumbrancesByFacility.forEach((encumbrance) => {
				result.push({
					id: nanoid(),
					facilityId: facility.id,
					info: getFacilityInfo(facility).join(', '),
					owners: (facility.owners || []).map((owner) => owner.id),
					address: utils.take(facility, 'address.mergedAddress'),
					encumbrance,
				})
			})
		} else {
			result.push({
				id: nanoid(),
				facilityId: facility.id,
				info: getFacilityInfo(facility).join(', '),
				owners: (facility.owners || []).map((owner) => owner.id),
				address: utils.take(facility, 'address.mergedAddress'),
				encumbrance: null,
			})
		}
	})

	return result
}

const convertDocument = ({ documentItem, participants }) => {
	return {
		id: documentItem.id,
		title: fileUtils.removeFileExtension(documentItem.title),
		createdAt: documentItem.createdAt,
		signatures: documentItem.signatures.map((signature) =>
			formatSignature(signature, participants)
		),
	}
}

export const fetchRosreestrEncumbrances = (loanId) => (dispatch, getState) => {
	return api.rosreestr
		.find({ filter: { loanId } })
		.then(({ data }) => {
			const { content } = data
			const facilities = getState().facility.list.data
			const convertedEncumbrances = convertEncumbrances(content, facilities)

			dispatch(setDataIn({ key: 'encumbrances', data: convertedEncumbrances }))

			return convertedEncumbrances
		})
		.catch((err) => {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить обременения',
					details: utils.getDetailsFromError(err),
				})
			)

			return err
		})
}

const transformParticipant = (participant, type) => {
	const director =
		type === PARTICIPANTS_TYPES.ORGANIZATION
			? participant.participants?.find(({ roles }) => roles.includes('DIRECTOR'))
			: null

	return {
		name: utils.getFullName(participant) || null,
		clientId:
			(type === PARTICIPANTS_TYPES.ORGANIZATION
				? director?.id
				: participant.$$type === PARTICIPANTS_TYPES.ENTREPRENEUR
				? participant.person?.id
				: participant.id) || null,
		roles: participant.roles || [],
		customerType: type,
		subjectId: participant.konturInfo?.subjectId || null,
		customerId: participant.id || null,
		activeCertificate: hasActiveCertificate(participant),
		director: director
			? {
					id: director.id || null,
					subjectId: director.participant?.konturInfo?.subjectId || null,
			  }
			: null,
		phone: director ? director?.participant?.phone : participant.phone,
	}
}

const getApplicants = () => async (_, getState) => {
	const { person, organization } = getState()

	const personList = person.list.data
	const organizationList = organization.list.data

	const participants = [...organizationList, ...personList].filter(({ roles = [] }) =>
		roles.some((role) =>
			[ROLES_VALUES.INVESTOR, ROLES_VALUES.OWNER, ROLES_VALUES.BORROWER].includes(role)
		)
	)

	const transformedParticipants = participants.map((participant) =>
		transformParticipant(participant, participant.$$type)
	)

	if (isEmpty(participants)) {
		return Promise.reject('Залогодатель, займодавец и (или) заёмщик не найдены')
	}

	// if (!hasDifferentInvestorAndOwner(participants)) {
	// 	return Promise.reject('Участник с ролями залогодатель и займодавец недопустим')
	// }

	// const participantsWithExpiredCertificate = participants.filter(
	// 	(e) => !hasActiveCertificate(e)
	// )

	// if (!isEmpty(participantsWithExpiredCertificate)) {
	// 	const names = participantsWithExpiredCertificate.map((participant) =>
	// 		utils.getFullName(participant)
	// 	)

	// 	return Promise.reject(`Найдены участники без активного сертификата: ${names.join(', ')}`)
	// }

	// const participantsWithoutLK = []

	// for (const participant of transformedParticipants) {
	// 	if (participant.roles.includes(ROLES_VALUES.INVESTOR)) continue

	// 	try {
	// 		await api.login.hasAccount(participant.phone)
	// 	} catch {
	// 		participantsWithoutLK.push(participant)
	// 	}
	// }

	// if (!isEmpty(participantsWithoutLK)) {
	// 	return Promise.reject(
	// 		`Найдены участники без личного кабинета: ${participantsWithoutLK
	// 			.map(utils.getFullName)
	// 			.join(', ')}`
	// 	)
	// }

	return Promise.resolve(
		transformedParticipants.map(omit(['clientId'])) // clientId больше не нужен
	)
}

// const hasDifferentInvestorAndOwner = (participants) => {
// 	const result = []
// 	const requiredRoles = [ROLES_VALUES.OWNER, ROLES_VALUES.INVESTOR]
// 	const participantsWithRequiredRole = {}

// 	// ищем залогодателя и займодавца с условием, что это разные участники
// 	// т.е. если у одного участника встречаются все необходимые роли, то это не валидно
// 	requiredRoles.forEach((role) => {
// 		const participant = participants.find((e) => e.roles?.includes(role))
// 		const participantRoles = (participant?.roles || []).filter((role) =>
// 			requiredRoles.includes(role)
// 		)

// 		if (
// 			participant &&
// 			!participantsWithRequiredRole[participant.id] &&
// 			participantRoles.length !== requiredRoles.length
// 		) {
// 			participantsWithRequiredRole[participant.id] = role
// 			result.push(true)
// 		} else {
// 			result.push(false)
// 		}
// 	})

// 	return !result.includes(false)
// }

const fetchStatementsSignStatus =
	({ contextId, encumbrances, participants }) =>
	async (dispatch, getState) => {
		const prevStatements = getState().rosreestr.statements
		const encumbrancesList =
			encumbrances ||
			getState()
				.rosreestr.encumbrances.map((facility) => facility.encumbrance)
				.filter(Boolean)

		const documentsId = []
		const allStatements = []
		const result = []

		if (!isEmpty(prevStatements)) {
			dispatch(setDataIn({ key: 'statements', data: [] }))
		}

		encumbrancesList.forEach((encumbrance) => {
			const statements = utils.take(encumbrance, 'konturInfo.statements') || []

			statements.forEach((statement) => {
				documentsId.push(statement.document.documentId)
				allStatements.push({
					encumbranceId: encumbrance.id,
					facilityId: encumbrance.facility.facilityId,
					statement,
				})
			})
		})

		const documents = []

		if (!isEmpty(documentsId)) {
			const response = await api.document
				.search({ filter: { id: documentsId } })
				.then(({ data }) => documents.push(...data.content))
				.catch((err) => err)

			if (response instanceof Error || isEmpty(documents)) {
				return Promise.resolve([])
			}
		}

		const convertStatement = ({ data, participant, hasSignatureInProgress, signed }) => ({
			hasSignatureInProgress,
			encumbranceId: data.encumbranceId,
			facilityId: data.facilityId,
			signed,
			participant,
			statement: data.statement,
		})

		const checkSignCertificate = (signature, required) =>
			signature.context.contextId === contextId && required
				? signature.certificate
				: !signature.certificate

		allStatements.forEach((data) => {
			const signDocument = documents.find((item) => item.id === data.statement.document.documentId)
			const participant =
				participants.find(
					(e) => e.customerId === utils.take(data, 'statement.declarant.participant.customerId')
				) || null
			const hasSignatureInProgress = !!utils
				.take(signDocument, 'signatures', [])
				.find((signature) => checkSignCertificate(signature, false))
			const isSigned = !!utils
				.take(signDocument, 'signatures', [])
				.find((signature) => checkSignCertificate(signature, true))

			result.push(
				convertStatement({
					data,
					participant,
					hasSignatureInProgress,
					signed: hasSignatureInProgress ? false : isSigned,
				})
			)
		})

		if (!isEmpty(result)) {
			dispatch(setDataIn({ key: 'statements', data: result }))
		}
	}

export const initRosreestrEncumbrances = (loanId) => async (dispatch) => {
	try {
		const encumbrances = await dispatch(fetchRosreestrEncumbrances(loanId))

		if (isEmpty(encumbrances)) {
			return Promise.reject('Обременения не найдены')
		}

		if (encumbrances instanceof Error) {
			return Promise.reject('Для корректной работы не хватает данных об обременениях недвижимости')
		}

		const participants = await dispatch(getApplicants())

		dispatch(setDataIn({ key: 'participants', data: participants }))

		await dispatch(
			fetchStatementsSignStatus({
				encumbrances: encumbrances.map((facility) => facility.encumbrance).filter(Boolean),
				contextId: loanId,
				participants,
			})
		)

		await dispatch(collectDocuments(participants, loanId))
		await dispatch(initMortgages(loanId))
	} catch (error) {
		console.error(error)

		return Promise.reject(
			typeof error === 'string' ? error : 'Непредвиденная ошибка (см. в консоли)'
		)
	}
}

export const collectApplicationRosreestrEntities = (applicationId) => async (dispatch) => {
	try {
		const participants = await dispatch(getApplicants())

		dispatch(setDataIn({ key: 'participants', data: participants }))

		await dispatch(collectDocuments(participants, applicationId, 'APPLICATION'))
	} catch (error) {
		dispatch(
			addServerError({
				text:
					typeof error === 'string'
						? error
						: `Не удалось собрать необходимые данные для росреестра`,
				details: utils.getDetailsFromError(error),
			})
		)
	}
}

const collectDocuments =
	(participants, contextId, contextType = 'LOAN') =>
	async (dispatch, getState) => {
		const { files, categories: unparsedCategories } = getState().document
		const facilities = getState().facility.list.data
		const _categories = flatten(unparsedCategories.data.map((category) => category.categories))
		const allParticipants = []
			.concat(
				getState().organization.list.data,
				getState().person.list.data,
				getState().entrepreneur.list.data
			)
			.map(transformParticipant)

		const DOCUMENTS = {
			APPLICATION: ['AGREEMENT_FEES_DELAY', 'LIEN_CONTRACT', 'LOAN_CONTRACT', 'PAYMENT_SCHEDULE'],
			LOAN: [
				'AGREEMENT_FEES_DELAY',
				'LIEN_CONTRACT',
				'LOAN_CONTRACT',
				'EXPLANATORY_NOTE',
				'PAYMENT_SCHEDULE',
				'PAYMENT_CONFIRMATION',
				'GENERAL_LOAN_CONDITIONS',
				'EXTRACT_KPK_REGISTRY',
			],
			ORGANIZATION: [
				'CHARTER',
				'OGRN',
				'TAX_ID',
				'EGRUL',
				'APPROVAL_DECISION',
				'OTHER_DOCUMENTS',
				'POWER_OF_ATTORNEY',
			],
			PERSON: [
				'DIVORCE_CERTIFICATE',
				'SPOUSE_DEATH_CERTIFICATE',
				'MARRIAGE_CONTRACT',
				'DIVORCE_DECISION',
				'MARRIAGE_CERTIFICATE',
			],
			FACILITY: ['CONSENT_TO_REPLEDGE', 'CONSENT_OF_SPOUSE', 'ASSESSMENT'],
		}

		const fetchedCategories = await api.document
			.getCategories({
				filter: {
					signable: true,
					id: [].concat(DOCUMENTS.ORGANIZATION, DOCUMENTS.PERSON, DOCUMENTS.FACILITY),
				},
				size: 100,
			})
			.then(({ data }) => data.content)
			.catch((err) => {
				dispatch(
					addServerError({
						text: `Не удалось загрузить документы`,
						details: utils.getDetailsFromError(err),
					})
				)

				return err
			})

		const categories = [].concat(
			fetchedCategories,
			_categories.filter((category) => DOCUMENTS[contextType].includes(category.id))
		)

		if (fetchedCategories instanceof Error || isEmpty(fetchedCategories) || isEmpty(_categories)) {
			return Promise.reject(fetchedCategories)
		}

		const fetchedDocuments = await api.document
			.search({
				filter: {
					owners: [
						...participants.map((participant) => participant.customerId),
						...facilities.map((facility) => facility.id),
					],
					categories: fetchedCategories.map((category) => category.id),
				},
				size: 100,
			})
			.then(({ data }) => data.content)
			.catch((error) => {
				dispatch(
					addServerError({
						text: `Не удалось загрузить документы участника`,
						details: utils.getDetailsFromError(error),
					})
				)

				return error
			})

		if (fetchedDocuments instanceof Error || isEmpty(fetchedDocuments)) {
			return Promise.reject(fetchedDocuments)
		}

		const documents = [].concat(
			fetchedDocuments,
			files.data.filter((document) => DOCUMENTS[contextType].includes(document.categoryId))
		)

		const entitiesMap = []
		const result = []

		entitiesMap.push({
			id: contextId,
			type: contextType,
			title: { APPLICATION: 'Документы заявки', LOAN: 'Документы займа' }[contextType],
		})

		participants.map((participant) =>
			entitiesMap.push({
				id: participant.customerId,
				type: participant.customerType,
				title: `Документы — ${participant.name}`,
			})
		)

		facilities.map((facility) =>
			entitiesMap.push({
				id: facility.id,
				type: 'FACILITY',
				title: `Документы объекта — ${getFacilityInfo(facility).join(', ') || 'нет данных'}`,
			})
		)

		entitiesMap.forEach((scope) => {
			const documentsForEntity = []

			DOCUMENTS[scope.type].forEach((category) => {
				const categoryData = categories.find((e) => e.id === category)
				const filteredDocumentsByCategory = documents.filter(
					({ owners, categoryId }) =>
						categoryId === category && owners.map(({ id }) => id).includes(scope.id)
				)

				documentsForEntity.push({
					list: sort(
						(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
						filteredDocumentsByCategory
					).map((documentItem) =>
						convertDocument({
							participants: allParticipants,
							documentItem,
						})
					),
					statementScopes:
						categoryData.id === 'ASSESSMENT' && scope.type === 'FACILITY'
							? ['MORTGAGE']
							: ['ENCUMBRANCE'],
					categoryTitle: categoryData?.title,
				})
			})

			result.push({
				...scope,
				documents: documentsForEntity,
			})
		})

		if (!isEmpty(result)) {
			dispatch(setDataIn({ key: 'documents', data: result }))
		}

		return result
	}

const formatParticipantToRequest = (participant) => {
	const result = {}

	result.participant = {
		customerId: participant.customerId,
		subjectId: participant.subjectId,
		customerType: participant.customerType,
	}

	if (participant.customerType === PARTICIPANTS_TYPES.ORGANIZATION && participant.director) {
		result.representative = {
			personId: participant.director.id,
			subjectId: participant.director.subjectId,
		}
	}

	return result
}

export const createStatements = (forms) => async (dispatch, getState) => {
	const { facilityId, recordId, documents, loanId, participants } = forms
	const organizations = participants.filter(
		(e) => e.customerType === PARTICIPANTS_TYPES.ORGANIZATION
	)

	const body = [
		{
			declarants: participants.map(formatParticipantToRequest),
			documents: documents.map((id) => ({ documentId: id })),
			facility: { facilityId },
			loanId,
		},
	]

	if (!isEmpty(organizations)) {
		const response = await axios
			.all(
				organizations.map((data) => api.organization.syncSignatureOfRepresentative(data.customerId))
			)
			.catch((err) => err)

		if (response instanceof Error) {
			dispatch(
				addServerError({
					text: `Не удалось создать заявления`,
					details: utils.getDetailsFromError(response),
				})
			)

			return Promise.reject()
		}
	}

	return api.rosreestr
		.add(body)
		.then(({ data }) => {
			const participantsFromStore = getState().rosreestr.participants

			dispatch(updateEncumbrance(recordId, head(data)))
			dispatch(
				fetchStatementsSignStatus({
					contextId: loanId,
					participants: participantsFromStore,
				})
			)

			return data
		})
		.catch((err) => {
			dispatch(
				addServerError({
					text: `Не удалось создать заявления`,
					details: utils.getDetailsFromError(err),
				})
			)
		})
}

export const editStatements = (forms) => async (dispatch, getState) => {
	const { facilityId, recordId, documents, packageId, loanId, status, participants } = forms
	const organizations = participants.filter(
		(e) => e.customerType === PARTICIPANTS_TYPES.ORGANIZATION
	)

	const shouldRecreateStatements = [
		ROSREESTR_ENCUMBRANCE_STATUSES.TIME_OUT,
		ROSREESTR_ENCUMBRANCE_STATUSES.REJECTED,
		ROSREESTR_ENCUMBRANCE_STATUSES.ERROR,
	].includes(status)

	const body = {
		declarants: participants.map(formatParticipantToRequest),
		documents: documents.map((id) => ({ documentId: id })),
		facility: { facilityId },
	}

	if (shouldRecreateStatements) {
		body.loanId = loanId
	}

	const request = shouldRecreateStatements
		? () => api.rosreestr.add(loanId, [body])
		: () => api.rosreestr.edit(packageId, body)

	if (!isEmpty(organizations)) {
		const response = await axios
			.all(
				organizations.map((data) => api.organization.syncSignatureOfRepresentative(data.customerId))
			)
			.catch((err) => err)

		if (response instanceof Error) {
			dispatch(
				addServerError({
					text: `Не удалось отредактировать заявления`,
					details: utils.getDetailsFromError(response),
				})
			)

			return Promise.reject()
		}
	}

	if (shouldRecreateStatements) {
		const response = await api.rosreestr.delete(packageId).catch((err) => err)

		if (response instanceof Error) {
			dispatch(
				addServerError({
					text: `Не удалось отредактировать заявления`,
					details: utils.getDetailsFromError(response),
				})
			)

			return Promise.reject()
		}
	}

	return request()
		.then(({ data }) => {
			const participantsFromStore = getState().rosreestr.participants

			dispatch(updateEncumbrance(recordId, data))
			dispatch(
				fetchStatementsSignStatus({
					contextId: loanId,
					participants: participantsFromStore,
				})
			)

			return data
		})
		.catch((err) => {
			dispatch(
				addServerError({
					text: `Не удалось отредактировать заявления`,
					details: utils.getDetailsFromError(err),
				})
			)
		})
}

export const sendToRosreestr = (record, recordId) => (dispatch, getState) => {
	const _record = record || getState().rosreestr.encumbrances.find((item) => item.id === recordId)

	return api.rosreestr
		.sendToRosreestr(_record?.encumbrance?.id)
		.then(({ data }) => {
			dispatch(updateEncumbrance(_record?.id, data))

			return data
		})
		.catch((err) => {
			dispatch(
				addServerError({
					text: `Не удалось отправить заявления в Росреестр`,
					details: utils.getDetailsFromError(err),
				})
			)
		})
}

export const sendToSigning = (loanId, statement) => (dispatch) => {
	const body = {
		signer: {
			customerId: utils.take(statement, 'declarant.participant.customerId'),
			customerType: utils.take(statement, 'declarant.participant.customerType'),
		},
		context: {
			contextId: loanId,
			contextType: 'LOAN',
		},
		documents: [statement.document.documentId],
	}

	return api.document
		.addSigner(body)
		.then(({ data }) => {
			const response = head(data)

			if (response) {
				dispatch(updateStatementStatus({ id: response.id, status: true }))
			}

			return data
		})
		.catch((err) => {
			dispatch(
				addServerError({
					text: `Не удалось отправить документ на подпись`,
					details: utils.getDetailsFromError(err),
				})
			)
		})
}

export const removeRestriction = (forms) => (dispatch, getState) => {
	const { recordId, loanId, encumbranceRecord, participant } = forms
	const { encumbrances } = getState().rosreestr

	const record = encumbrances.find((item) => item.id === recordId) || {}

	const body = {
		declarants: [formatParticipantToRequest(participant)],
		documents: [],
		facility: { facilityId: record.facilityId, encumbranceRecord },
		loanId,
		api: 'CREDIT_CLUB',
	}

	return api.rosreestr
		.add([body])
		.then(({ data }) => {
			const participantsFromStore = getState().rosreestr.participants
			dispatch(addEncumbrance({ ...record, encumbrance: data[0] }))
			dispatch(
				fetchStatementsSignStatus({
					contextId: loanId,
					participants: participantsFromStore,
				})
			)
		})
		.catch((err) => {
			dispatch(
				addServerError({
					text: `Не удалось снять обременение`,
					details: utils.getDetailsFromError(err),
				})
			)
		})
}
