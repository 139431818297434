/* eslint-disable react-hooks/rules-of-hooks */
import {
	addEntrepreneurInList,
	deleteEntrepreneurInList,
	updateEntrepreneurInList,
} from 'actions/entrepreneur'
import { pushNotice } from 'actions/notices'
import {
	addOrganizationInList,
	deleteOrganizationInList,
	updateOrganizationInList,
} from 'actions/organization'
import {
	addPersonInList,
	deletePersonInList,
	replacePersonInList,
	updatePersonInList,
} from 'actions/person'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { ENTITY_TYPES } from 'const'
import { cond, flip, mergeRight, pick, prop, propEq, T, uniq, update } from 'rambda'

import { utils } from 'helpers'

import {
	findIndexById,
	getActionName,
	getChangableFieldsToApi,
	getChangableFieldsToStore,
	rejectById,
	transformRosreestrParticipant,
	transformToOrganizationRequest,
	transformToRequest,
	transformToRosreestrRegistrationRequest,
} from './helpers'

const listActions = {
	addPersonInList,
	addOrganizationInList,
	addEntrepreneurInList,

	replacePersonInList,

	updatePersonInList,
	updateEntrepreneurInList,
	updateOrganizationInList,

	deletePersonInList,
	deleteOrganizationInList,
	deleteEntrepreneurInList,
}

const propsToPickByTargetEntity = {
	[ENTITY_TYPES.APPLICATION]: ['roles'],
	[ENTITY_TYPES.DELIVERY]: ['mainContact'],
	[ENTITY_TYPES.LOAN]: ['roles'],
	[ENTITY_TYPES.LEAD]: ['roles'],
	[ENTITY_TYPES.FACILITY]: ['part'],
	[ENTITY_TYPES.ORGANIZATION]: ['roles'],
	[ENTITY_TYPES.ENTREPRENEUR]: [],
	[ENTITY_TYPES.ROSREESTR_REGISTRATION]: ['roles'],
}

/** Доступ к изменяемому полю таргет-сущности */
const fieldToUpdate = {
	[ENTITY_TYPES.APPLICATION]: 'participants',
	[ENTITY_TYPES.DELIVERY]: 'participants',
	[ENTITY_TYPES.LOAN]: 'participants',
	[ENTITY_TYPES.LEAD]: 'participants',
	[ENTITY_TYPES.FACILITY]: 'owners',
	[ENTITY_TYPES.ORGANIZATION]: 'participants',
	[ENTITY_TYPES.ENTREPRENEUR]: 'personId',
	[ENTITY_TYPES.ROSREESTR_REGISTRATION]: 'declarants',
}

/** Действие в сторе для разных типов сущности */
const addActionTargetNames = {
	[ENTITY_TYPES.APPLICATION]: 'add',
	[ENTITY_TYPES.DELIVERY]: 'add',
	[ENTITY_TYPES.LOAN]: 'add',
	[ENTITY_TYPES.LEAD]: 'add',
	[ENTITY_TYPES.FACILITY]: 'add',
	[ENTITY_TYPES.ORGANIZATION]: 'add',
	[ENTITY_TYPES.ROSREESTR_REGISTRATION]: 'add',
	[ENTITY_TYPES.ENTREPRENEUR]: 'replace',
}

const mappedApi = {
	[ENTITY_TYPES.PERSON]: api.person,
	[ENTITY_TYPES.APPLICATION]: api.application,
	[ENTITY_TYPES.DELIVERY]: api.delivery,
	[ENTITY_TYPES.LOAN]: api.loan,
	[ENTITY_TYPES.LEAD]: api.lead,
	[ENTITY_TYPES.FACILITY]: api.facility,
	[ENTITY_TYPES.ORGANIZATION]: api.organization,
	[ENTITY_TYPES.ROSREESTR_REGISTRATION]: api.rosreestrRegistration,
	[ENTITY_TYPES.ENTREPRENEUR]: api.entrepreneur,
}

const CHANGE_ORG_ROLE_TEXT =
	'Роль подписанта перенесена на нового участника, в организации может быть только один подписант'

/** возвращает массив тех полей, которые можно изменить в участниках таргет-сущности */
const getPropsToPick = (key) => propsToPickByTargetEntity[key]

const getAction = flip(prop)(listActions)
const getActionTargetName = flip(prop)(addActionTargetNames)
const getFieldToUpdate = flip(prop)(fieldToUpdate)
const getApiService = flip(prop)(mappedApi)

const addEntityToTarget =
	({ entity, target }, list) =>
	async (dispatch) => {
		const propsToPick = getPropsToPick(target.type)
		const action = getAction(getActionName(getActionTargetName(target.type), entity.type))
		const update = getAction(getActionName('update', entity.type))

		const queuedStoreUpdates = []
		const queuedNotifications = []

		const { data: createdOrExistedEntity } = entity.dataForCreatingEntity
			? await getApiService(entity.type).create(entity.dataForCreatingEntity)
			: await getApiService(entity.type).get(entity.subject.id)

		// Для ИП нужен только id персоны, в остальных случаях это массив объектов
		const getDataToChangeByTarget = cond([
			[propEq(ENTITY_TYPES.ENTREPRENEUR, 'type'), () => createdOrExistedEntity.id],
			[
				propEq(ENTITY_TYPES.ORGANIZATION, 'type'),
				() =>
					transformToOrganizationRequest({
						sideParticipants: {
							list,
							propsToPick: getPropsToPick(target.type),
							addQueuedNotice: () =>
								queuedNotifications.push(() =>
									dispatch(pushNotice({ message: CHANGE_ORG_ROLE_TEXT }))
								),
							addQueuedStoreUpdate: (payload) => queuedStoreUpdates.push(payload),
						},
						createdOrExistedEntity: {
							data: Object.assign(
								{},
								createdOrExistedEntity,
								pick(propsToPick, getChangableFieldsToApi(entity))
							),
							propsToPick: getPropsToPick(target.type),
						},
					}),
			],
			[
				propEq(ENTITY_TYPES.ROSREESTR_REGISTRATION, 'type'),
				() =>
					[].concat(
						transformToRosreestrRegistrationRequest({
							list,
							propsToPick: getPropsToPick(target.type),
						}),
						transformRosreestrParticipant({
							data: Object.assign(
								{},
								createdOrExistedEntity,
								pick(propsToPick, getChangableFieldsToApi(entity))
							),
							propsToPick: getPropsToPick(target.type),
						})
					),
			],
			[
				T,
				() =>
					[].concat(
						transformToRequest({ list, propsToPick: getPropsToPick(target.type) }),
						mergeRight(
							{ id: createdOrExistedEntity.id, type: entity.type },
							pick(propsToPick, getChangableFieldsToApi(entity))
						)
					),
			],
		])

		// Для ИП необходимо заменять текущую персону в сторе, а не добавлять
		const actionParameters = mergeRight(createdOrExistedEntity, getChangableFieldsToStore(entity))
		const getModifiedActionParametersByTarget = cond([
			[
				propEq('type', ENTITY_TYPES.ENTREPRENEUR),
				() => ({ id: list[0]?.id, value: actionParameters }),
			],
			[T, () => actionParameters],
		])

		await getApiService(target.type).update(target.id, {
			[getFieldToUpdate(target.type)]: getDataToChangeByTarget(target),
		})

		queuedStoreUpdates.map((payload) => dispatch(update(payload)))
		queuedNotifications.map((fn) => fn())

		dispatch(action(getModifiedActionParametersByTarget(target)))
	}

const updateEntityToTarget =
	({ target, entity }, indexForUpdate, list) =>
	async (dispatch) => {
		const action = getAction(getActionName('update', entity.type))
		const propsToPick = getPropsToPick(target.type)

		const queuedStoreUpdates = []
		const queuedNotifications = []

		// Оставляем роль заявителя, если она есть (но только если мы не в лидах)
		const changableFields = {
			roles: propsToPick.includes('roles')
				? list[indexForUpdate].roles.includes('APPLICANT') && target.type !== ENTITY_TYPES.LEAD
					? uniq(['APPLICANT', ...entity.roles])
					: entity.roles
				: [],
			part: entity.part,
		}

		const getDataToChangeByTarget = cond([
			[
				propEq(ENTITY_TYPES.ROSREESTR_REGISTRATION, 'type'),
				() =>
					transformToRosreestrRegistrationRequest({
						list: update(
							indexForUpdate,
							mergeRight(
								list[indexForUpdate],
								pick(propsToPick, getChangableFieldsToApi(changableFields))
							),
							list
						),
						propsToPick: getPropsToPick(target.type),
					}),
			],
			[
				propEq(ENTITY_TYPES.ORGANIZATION, 'type'),
				() =>
					transformToOrganizationRequest({
						sideParticipants: {
							list: rejectById(entity.subject.id, list),
							propsToPick: getPropsToPick(target.type),
							addQueuedNotice: () =>
								queuedNotifications.push(() =>
									dispatch(pushNotice({ message: CHANGE_ORG_ROLE_TEXT }))
								),
							addQueuedStoreUpdate: (payload) => queuedStoreUpdates.push(payload),
						},
						createdOrExistedEntity: {
							data: mergeRight(
								list[indexForUpdate],
								pick(propsToPick, getChangableFieldsToApi(changableFields))
							),
							propsToPick: getPropsToPick(target.type),
						},
					}),
			],
			[
				T,
				() =>
					transformToRequest({
						list: update(
							indexForUpdate,
							mergeRight(
								list[indexForUpdate],
								pick(propsToPick, getChangableFieldsToApi(changableFields))
							),
							list
						),
						propsToPick,
					}),
			],
		])

		await getApiService(target.type).update(target.id, {
			[getFieldToUpdate(target.type)]: getDataToChangeByTarget(target),
		})

		queuedStoreUpdates.map((payload) => dispatch(action(payload)))
		queuedNotifications.map((fn) => fn())

		dispatch(action({ id: entity.subject.id, value: getChangableFieldsToStore(changableFields) }))
	}

export const addEntity = (parameters) => async (dispatch, getState) => {
	const { entity } = parameters
	const { person, entrepreneur, organization } = getState()
	const list = [].concat(person.list.data, organization.list.data, entrepreneur.list.data)
	const indexToUpdate = findIndexById(entity.subject.id, list)
	// add — если сущности нет в сторе, update — если уже есть.
	// В первом случае мы её должны сначала либо создать (метод create в api), либо получить (метод get в api)
	// и добавить в соответствующую коллекцию. Во втором — просто обновить нужные поля.
	const targetAction = indexToUpdate < 0 ? 'add' : 'update'

	try {
		if (targetAction === 'add') {
			await dispatch(addEntityToTarget(parameters, list))
		} else {
			await dispatch(updateEntityToTarget(parameters, indexToUpdate, list))
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.dir(error)

		dispatch(
			addServerError({
				text: 'Ошибка добавления элемента',
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const updateEntity = (parameters) => async (dispatch, getState) => {
	const { entity } = parameters
	const { person, entrepreneur, organization } = getState()
	const list = [].concat(person.list.data, organization.list.data, entrepreneur.list.data)
	const indexToUpdate = findIndexById(entity.subject.id, list)

	try {
		await dispatch(updateEntityToTarget(parameters, indexToUpdate, list))
	} catch (error) {
		// eslint-disable-next-line no-console
		console.dir(error)

		dispatch(
			addServerError({
				text: 'Ошибка обновления элемента',
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const deleteEntity =
	({ target, entity }) =>
	async (dispatch, getState) => {
		const { person, entrepreneur, organization } = getState()
		const list = [].concat(person.list.data, organization.list.data, entrepreneur.list.data)
		const propsToPick = getPropsToPick(target.type)
		const action = getAction(getActionName('delete', entity.type))

		const getDataToChangeByTarget = cond([
			[propEq(ENTITY_TYPES.ENTREPRENEUR, 'type'), () => null],
			[
				propEq(ENTITY_TYPES.ROSREESTR_REGISTRATION, 'type'),
				() =>
					[].concat(
						transformToRosreestrRegistrationRequest({
							list: rejectById(entity.subject.id, list),
							propsToPick,
						})
					),
			],
			[
				T,
				() =>
					transformToRequest({
						list: rejectById(entity.subject.id, list),
						propsToPick,
					}),
			],
		])

		try {
			await getApiService(target.type).update(target.id, {
				[getFieldToUpdate(target.type)]: getDataToChangeByTarget(target),
			})

			dispatch(action(entity.subject.id))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Ошибка удаления элемента',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}
