import { sentryUtils } from '@creditclubteam/helpers'

export const sentryConfig = {
	enabled: window.__env__ === 'production',
	environment: window.__env__.BUILD_MODE,
	debug: window.__env__.BUILD_MODE === 'development',
	dsn: 'https://5e20fec8e639437e8aa33c06cac4f262@o272008.ingest.sentry.io/1543133',
	ignoreErrors: ['Non-Error exception captured', 'Request aborted', 'timeout of 10000ms exceeded'],

	beforeBreadcrumb(breadcrumb) {
		if (sentryUtils.shouldIgnoreBreadcrumb(breadcrumb)) {
			return null
		}

		return breadcrumb
	},

	beforeSend(event, hint) {
		if (sentryUtils.shouldSendError(hint?.originalException)) {
			return event
		}

		return null
	},
}
