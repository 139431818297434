import { Component, PureComponent } from 'react'
import { connect } from 'react-redux'
import { addEntity, deleteEntity, updateEntity } from 'actions/common/participants'
import { leadUpdate, sendLeadEsiaAuthLink } from 'actions/lead'
import { sendGosusligiAuthLink } from 'actions/person'
import cx from 'clsx'
import { ENTITY_TYPES } from 'const'
import PropTypes from 'prop-types'

import { utils } from 'helpers'
import { getAvailablePart } from 'helpers/getAvailablePart'

import AddForm from './AddForm'
import LoadingBlock from './LoadingBlock'
import Participant from './Participant'
import { sendApplicationEsiaAuthLink } from 'actions/application'

/**
 * @param {bool} isEdit - Показывает форму добавления а так же разрешает возможность удалять участников и изменять их данные
 * @param {object} entity - Название и id сущности например {name: application, id: 356}
 */
class ParticipantsBlock extends Component {
	static propTypes = {
		isEdit: PropTypes.bool,
		isLead: PropTypes.bool,
		isApplication: PropTypes.bool,
		entity: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
		person: PropTypes.object.isRequired,
		entrepreneur: PropTypes.object.isRequired,
		organization: PropTypes.object.isRequired,
		pathOut: PropTypes.string,
		defineSearchScopes: PropTypes.func,
		wrapClassName: PropTypes.string,
		allowChanges: PropTypes.bool,
		participants: PropTypes.arrayOf(PropTypes.object),
		availablePart: PropTypes.number,
		setAvailablePartForOwners: PropTypes.func,

		// addForm - только в режиме isEdit
		isFetchingParticipants: PropTypes.bool,

		// Все функции только в режиме isEdit
		onAdd: PropTypes.func,
		onChange: PropTypes.func,
		onDelete: PropTypes.func,
		sendGosusligiAuthLink: PropTypes.func,
	}

	cancelableAddParticipantPromise = null

	state = {
		addingProcess: false,
	}

	static defaultProps = {
		isEdit: false,
		allowChanges: true,
	}

	componentWillUnmount() {
		this.cancelableAddParticipantPromise?.cancel()
	}

	handleAddParticipant = async (data) => {
		const { addEntity, onAdd } = this.props

		this.setState({ addingProcess: true })

		const callback = onAdd || addEntity

		this.cancelableAddParticipantPromise = utils.makeCancelable(callback(data))

		this.cancelableAddParticipantPromise.promise
			.then(() => this.setState({ addingProcess: false }))
			.catch((error) => {
				if (error.isCanceled) return
				this.setState({ addingProcess: false })
			})
	}

	handleChangeParticipant = (results) => {
		const { onChange, updateEntity } = this.props

		const callback = onChange || updateEntity

		return callback(results)
	}

	handleDeleteParticipant = (results) => {
		const { onDelete, deleteEntity } = this.props

		const callback = onDelete || deleteEntity

		return callback(results)
	}

	handleUpdateParticipant = (args) => {
		const i = this.props.participants.findIndex((el) => el.id === args.id)

		const copy = JSON.parse(JSON.stringify(this.props.participants))

		copy[i].roles = args.roles

		this.props.leadUpdate({ participants: copy })
	}

	render() {
		const {
			isEdit,
			entity,
			resetAddForm,
			participants,
			wrapClassName,
			allowChanges,
			defineSearchScopes,
			availablePart,
			isFetchingParticipants,
			pathOut,
			sendLeadEsiaAuthLink,
			sendApplicationEsiaAuthLink,
			sendGosusligiAuthLink,
			isLead,
			isApplication,
			lead,
			application,
		} = this.props

		const { addingProcess } = this.state

		const addFormOptions = {
			entity,
			availablePart,
			defineSearchScopes,
			resetAddForm,
			addingProcess,
			participantsData: participants,
			onAddParticipant: this.handleAddParticipant,
		}

		return (
			<div data-test-id='participants-block' className={cx('tabs-block-wrapper', wrapClassName)}>
				{isEdit && <AddForm {...addFormOptions} />}
				{isFetchingParticipants ? (
					<LoadingBlock />
				) : utils.hasObjectLength(participants) ? (
					participants.map((participant) => {
						const participantProps = {
							isEdit,
							entity,
							availablePart,
							...participant,
							pathOut,
							participantsData: participants,
							allowChanges,
							sendGosusligiAuthLink,
							sendLeadEsiaAuthLink,
							sendApplicationEsiaAuthLink,
							onDelete: this.handleDeleteParticipant,
							onChange: this.handleChangeParticipant,
							isApplicantSet: !!participants?.find(({ roles }) => roles.includes('APPLICANT')),
							isLead,
							isApplication,
							parentId: isLead && lead.id || isApplication && application.id,
						}

						return <Participant key={participant.id} {...participantProps} />
					})
				) : (
					<div className='participants-block__empty'>Сюда ещё ничего не добавляли</div>
				)}
			</div>
		)
	}
}

const WithPart = (Component) => {
	return class extends PureComponent {
		getParticipants = () => {
			return (
				this.props.participants ??
				[].concat(
					this.props.person.data,
					this.props.entrepreneur.data,
					this.props.organization.data
				)
			)
		}

		getParicipantsFetchingStatus = () => {
			return (
				this.props.person.fetching ||
				this.props.entrepreneur.fetching ||
				this.props.organization.fetching ||
				this.props.isFetchingParticipants
			)
		}

		render() {
			const participants = this.getParticipants()

			return (
				<Component
					{...this.props}
					availablePart={
						this.props.entity.name === ENTITY_TYPES.FACILITY ? getAvailablePart(participants) : 0
					}
					participants={participants}
					isFetchingParticipants={this.getParicipantsFetchingStatus()}
				/>
			)
		}
	}
}

const mapStateToProps = (state) => ({
	lead: state.lead.single.data,
	application: state.application.single.data,
	person: state.person.list,
	entrepreneur: state.entrepreneur.list,
	organization: state.organization.list,
})

const mapDispatchToProps = {
	addEntity,
	updateEntity,
	deleteEntity,
	leadUpdate,
	sendLeadEsiaAuthLink,
	sendGosusligiAuthLink,
	sendApplicationEsiaAuthLink,
}

export default connect(mapStateToProps, mapDispatchToProps)(WithPart(ParticipantsBlock))
