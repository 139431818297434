import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { push } from 'connected-react-router'
import { actions } from 'reducers/broker/forms/organization'
import url from 'routes/urls'

import { utils } from 'helpers'

import { formatOrganizationForm } from '../helpers'
import { getOrganization } from '../single'

import { createBrokerByForm } from './broker'
import { checkOrganizationForm, resetMain, setProcessStatus } from './main'

export const resetForm = actions.reset
export const setEditOrganizationForm = actions.edit
export const setOrganizationFormField = actions.setField

export const createOrganization = () => async (dispatch, getState) => {
	if (!dispatch(checkOrganizationForm())) return
	const { organization } = getState().broker.forms

	dispatch(setProcessStatus(true))
	try {
		const { data } = await api.broker.create(formatOrganizationForm(organization))

		if (
			(organization.contact?.phone || organization.contactPhone) &&
			(organization.contactEmail || organization.contact?.email) &&
			organization.contact.name &&
			organization.contact.surname
		) {
			await dispatch(createBrokerByForm(data.id, organization))
		}

		dispatch(push(url.broker.path(data.id)))
	} catch (err) {
		dispatch(
			addServerError({
				text: 'Не удалось создать организацию',
				details: utils.getDetailsFromError(err),
			})
		)
	} finally {
		dispatch(setProcessStatus(false))
	}
}

export const editOrganization = (orgId, form) => (dispatch) => {
	dispatch(setProcessStatus(true))

	return api.broker
		.update(form, orgId)
		.then(() => {
			dispatch(getOrganization(orgId))
		})
		.catch((err) => {
			dispatch(
				addServerError({
					text: 'Не удалось обновить организацию',
					details: utils.getDetailsFromError(err),
				})
			)
		})
		.finally(() => dispatch(setProcessStatus(false)))
}

export const resetOrganizationForm = () => (dispatch) => {
	dispatch(resetMain())
	dispatch(resetForm())
}
