import { combineReducers } from 'redux'

import broker from './broker'
import main from './main'
import organization from './organization'

export default combineReducers({
	main,
	broker,
	organization,
})
