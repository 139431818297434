/* eslint-disable no-debugger */
import { replaceFile } from 'actions/document'
import { addDownloadFileStatus, hideNotice } from 'actions/notices'
import {
	deleteCertificateFormForOrganization,
	updateCertificateForOrganization,
} from 'actions/organization'
import { deleteCertificateFormForPerson, updateCertificateForPerson } from 'actions/person'
import { addServerError } from 'actions/serverErrors'
import { uploadFiles } from 'actions/uploadFiles'
import api from 'api'
import axios from 'axios'
import { KONTUR_CERTIFICATE_STATUSES, ROLES_VALUES } from 'const'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { flatten, isEmpty } from 'ramda'
import { object, string } from 'yup'

import { downloadFile } from '@creditclubteam/helpers'
import { utils } from 'helpers'

const RELEASE_CERT_STATEMENT = 'RELEASE_CERT_STATEMENT'
const ARCHIVE_RELEASE_CERT_STATEMENT = 'ARCHIVE_RELEASE_CERT_STATEMENT'

const replaceReleaseCertStatementToArchive = (ownerId) => (dispatch, getState) => {
	const documents = getState().document.files.data.filter(
		(e) => e.categoryId === RELEASE_CERT_STATEMENT
	)

	if (isEmpty(documents)) {
		return Promise.resolve()
	}

	return axios
		.all(
			documents.map((e) =>
				api.document.replace(e.id, {
					categoryId: ARCHIVE_RELEASE_CERT_STATEMENT,
					newOwnerId: ownerId,
					ownerId,
				})
			)
		)
		.then((response) => {
			const data = response.map(({ data }) => data)

			data.forEach((documentItem) => {
				dispatch(replaceFile(documentItem.id, documentItem))
			})
		})
		.catch((err) => {
			dispatch(
				addServerError({
					err,
					text: 'Ошибка при переносе файлов в другую категорию',
					details: utils.getDetailsFromError(err),
				})
			)

			return {
				error: true,
			}
		})
}

export const createOrUpdateCertificate = (id, type) => async (dispatch) => {
	if (!type) {
		return Promise.reject()
	}

	const response = await dispatch(replaceReleaseCertStatementToArchive(id))

	if (response && response.error) {
		return Promise.reject()
	}

	return api[type.toLowerCase()]
		.createOrUpdateCertificate(id)
		.then(({ data }) => {
			if (type.toUpperCase() === PARTICIPANTS_TYPES.PERSON) {
				dispatch(updateCertificateForPerson(data))
			} else if (type.toUpperCase() === PARTICIPANTS_TYPES.ORGANIZATION) {
				dispatch(updateCertificateForOrganization(data))
			}

			return data
		})
		.catch((err) => {
			const {
				response: { status },
				response: { data },
			} = err
			const errors = utils.take(data, 'errors.errors') || []
			const isKonturError = status === 400 && utils.take(data, 'errorCode') === 'kontur_api'

			dispatch(
				addServerError({
					err,
					text: 'Не удалось создать заявление',
					details: utils.getDetailsFromError(err),
				})
			)

			throw {
				errors: isKonturError ? errors : [],
			}
		})
}

export const updateCertificateStatus = (type) => (dispatch, getState) => {
	if (!type) {
		return Promise.resolve({ shouldPolling: false })
	}

	const subject =
		type.toUpperCase() === PARTICIPANTS_TYPES.PERSON
			? getState().person.single.data
			: getState().organization.single.data

	let shouldPolling = false

	const checkShouldPolling = (status) =>
		[
			KONTUR_CERTIFICATE_STATUSES.VALIDATING,
			KONTUR_CERTIFICATE_STATUSES.VALIDATED,
			KONTUR_CERTIFICATE_STATUSES.RELEASING,
		].includes(status)

	if (checkShouldPolling(utils.take(subject, 'konturInfo.form.state'))) {
		shouldPolling = true
	}

	if (!shouldPolling) {
		return Promise.resolve({ shouldPolling: false })
	}

	return api[type.toLowerCase()]
		.updateCertificateFormState(subject.id)
		.then(({ data }) => {
			const subject =
				type.toUpperCase() === PARTICIPANTS_TYPES.PERSON
					? getState().person.single.data
					: getState().organization.single.data

			if (isEmpty(subject)) {
				return { shouldPolling: false }
			}

			if (utils.take(subject, 'konturInfo.form.state') !== utils.take(data, 'form.state')) {
				dispatch(
					type.toUpperCase() === PARTICIPANTS_TYPES.PERSON
						? updateCertificateForPerson(data)
						: updateCertificateForOrganization(data)
				)
			}

			if (!checkShouldPolling(utils.take(data, 'form.state'))) {
				return { shouldPolling: false }
			}

			return { shouldPolling: true }
		})
		.catch((err) => {
			const {
				response: { status },
				response: { data },
			} = err
			const errors = utils.take(data, 'errors.errors') || []
			const isKonturError = status === 400 && utils.take(data, 'errorCode') === 'kontur_api'

			throw { shouldPolling: true, errors: isKonturError ? errors : [] }
		})
}

export const validateSubjectForCertificate = (type) => async (dispatch, getState) => {
	const validationResult = {
		subjects: [],
		type,
	}

	if (type.toUpperCase() === PARTICIPANTS_TYPES.PERSON) {
		const person = getState().person.single.data
		const documents = getState().document.files.data
		const categories = flatten(getState().document.categories.data.map((e) => e.categories))

		const { hasError, personErrors } = await validateCertificateForPerson({
			person,
			documents,
			categories,
		})

		if (hasError || personErrors.documents.length > 0) {
			validationResult.subjects.push({
				subject: person,
				errors: personErrors,
			})
		}
	} else if (type.toUpperCase() === PARTICIPANTS_TYPES.ORGANIZATION) {
		const subject = getState().organization.single.data
		const documents = getState().document.files.data
		const categories = flatten(getState().document.categories.data.map((e) => e.categories))

		const director = getState().person.list.data.find((e) =>
			e.roles?.includes(ROLES_VALUES.DIRECTOR)
		)

		const { hasError: orgErrorBool, organizationErrors } = validateCertificateForOrganization(
			subject,
			documents,
			categories,
			director
		)

		const { hasError: personErrorBool, personErrors } = await validateCertificateForPerson({
			person: director,
		})

		if (orgErrorBool) {
			validationResult.subjects.push({
				subject,
				errors: organizationErrors,
			})
		}

		if (personErrorBool) {
			validationResult.subjects.push({
				subject: director,
				errors: personErrors,
			})
		}
	}

	return new Promise((resolve, reject) => {
		if (
			isEmpty(validationResult.subjects) ||
			validationResult.subjects.find((subject) => subject.errors.documents.length > 0)
		) {
			resolve(validationResult)
		} else {
			reject(validationResult)
		}
	})
}

const validateCertificateForPerson = async (data) => {
	const { person } = data

	if (!person) {
		return {
			hasError: false,
			personErrors: {
				documents: [],
				other: [],
			},
		}
	}

	const requiredDocs = [
		'PASSPORT_MAIN',
		'SNILS',
		'TAX_ID',
		'SELFIE_WITH_PASSPORT',
		'SELFIE_APPLICATION_CDS',
	]

	const documentErrors = []

	const categories = isEmpty(data.categories || [])
		? await api.document
				.getCategories({
					filter: {
						ownerTypes: [PARTICIPANTS_TYPES.PERSON],
						out: 'ROSREESTR_DOCUMENTS',
					},
				})
				.then(({ data }) => data.content)
				.catch(() => [])
		: data.categories

	const documents = isEmpty(data.documents || [])
		? await api.document
				.search({
					filter: {
						owners: [person.id],
					},
				})
				.then(({ data }) => data.content)
				.catch(() => [])
		: data.documents

	requiredDocs.forEach((category) => {
		const filteredDocs = documents.filter((e) => e.categoryId === category)

		if (!filteredDocs.length) {
			const translatedCategory = categories.find((e) => e.id === category)
			documentErrors.push(translatedCategory ? translatedCategory.title : category)
		}
	})

	const otherErrors = []

	const validationText = {
		// https://gitlab.com/creditclubteam/projects/platform/-/issues/4905
		// remove passport.married check
		// married: 'семейное положение',
		number: 'номер паспорта',
		series: 'серия паспорта',
		birthDate: 'дата рождения',
		issueDate: 'дата выдачи паспорта',
		divisionCode: 'код подразделения',
		department: 'кем выдан',
		birthplace: 'место рождения',
		snils: 'СНИЛС',
	}

	if (!utils.take(person, 'form.passport.registration.mergedAddress')) {
		otherErrors.push('адрес регистрации')
	}

	try {
		await object({
			form: object({
				passport: object({
					number: string().required('номер паспорта'),
					series: string().required('серия паспорта'),
					birthDate: string().required('дата рождения'),
					issueDate: string().required('дата выдачи паспорта'),
					divisionCode: string().required('код подразделения'),
					department: string().required('кем выдан'),
					birthplace: string().required('место рождения'),
				}),
				snils: string().required('СНИЛС'),
			}),
		}).validate(person)
	} catch (error) {
		otherErrors.push(...error.errors)
	}

	const hasError = !isEmpty(otherErrors)

	return {
		hasError,
		personErrors: {
			documents: documentErrors,
			other: otherErrors,
		},
	}
}

const validateCertificateForOrganization = (organization, documents, categories, director) => {
	const requiredDocs = ['EGRUL', 'REPRESENTATIVE']

	const documentErrors = []

	requiredDocs.forEach((category) => {
		const filteredDocs = documents.filter((e) => e.categoryId === category)

		if (!filteredDocs.length) {
			const translatedCategory = categories.find((e) => e.id === category)
			documentErrors.push(translatedCategory ? translatedCategory.title : category)
		}
	})

	const EGRULDocument = documents.find((e) => e.categoryId === 'EGRUL')
	const REPRESENTATIVEDocument = documents.find((e) => e.categoryId === 'REPRESENTATIVE')

	const otherErrors = []

	if (!utils.take(organization, 'form.actualAddress.mergedAddress')) {
		otherErrors.push('адрес регистрации')
	}

	if (!utils.take(organization, 'form.legalAddress.mergedAddress')) {
		otherErrors.push('юридический адрес')
	}

	if (!utils.take(organization, 'lastZchbUpdate')) {
		otherErrors.push('информация "ЗЧБ"')
	}

	if (!utils.take(REPRESENTATIVEDocument, 'metadata.creationDate')) {
		documentErrors.push('дата формирования приказа о назначении директора')
	}

	if (!utils.take(EGRULDocument, 'metadata.number')) {
		documentErrors.push('номер ЕГРЮЛ')
	}

	if (!utils.take(REPRESENTATIVEDocument, 'metadata.number')) {
		documentErrors.push('номер приказа о назначении директора')
	}

	if (!utils.take(EGRULDocument, 'metadata.creationDate')) {
		documentErrors.push('дата формирования ЕГРЮЛ')
	} else {
		const expiredAt = moment(EGRULDocument.metadata.creationDate, 'YYYY-MM-DD').add({ days: 30 })

		const isActive = moment().isBetween(
			moment(EGRULDocument.metadata.creationDate, 'YYYY-MM-DD'),
			expiredAt
		)

		if (!isActive) {
			documentErrors.push('дата формирования ЕГРЮЛ неактивна')
		}
	}

	const participantErrors = []
	if (!director) {
		participantErrors.push('добавить директора')
	}

	const hasError = !isEmpty(documentErrors) || !isEmpty(otherErrors) || !isEmpty(participantErrors)

	return {
		hasError,
		organizationErrors: {
			documents: documentErrors,
			other: otherErrors,
			participants: participantErrors,
		},
	}
}

export const createStatement = (id, type) => async (dispatch) => {
	if (!type) {
		return Promise.reject()
	}

	const statement = await api[type.toLowerCase()]
		.createCertificateStatement(id)
		.then(({ data }) => data)
		.catch((err) => {
			dispatch(
				addServerError({
					err,
					text: 'Не удалось скачать заявление',
					details: utils.getDetailsFromError(err),
				})
			)
		})

	if (statement) {
		saveAs(`data:application/pdf;base64,${statement.content}`, statement.name)

		return Promise.resolve()
	} else {
		return Promise.reject()
	}
}

export const uploadStatement =
	({ id, type, file }) =>
	(dispatch) => {
		const RELEASE_CERT_STATEMENT = 'RELEASE_CERT_STATEMENT'

		return new Promise((resolve, reject) => {
			if (!file || !id || !type) {
				return reject()
			}

			dispatch(
				uploadFiles({
					files: [file],
					ownerId: id,
					ownerType: type,
					onFailure: () => {
						reject(new Error(RELEASE_CERT_STATEMENT))
					},
					categoryId: RELEASE_CERT_STATEMENT,
					onSuccess: () => {
						return api[type.toLowerCase()]
							.releaseCertificateForm(id)
							.then(({ data }) => {
								if (type.toUpperCase() === PARTICIPANTS_TYPES.PERSON) {
									dispatch(updateCertificateForPerson(data))
								} else if (type.toUpperCase() === PARTICIPANTS_TYPES.ORGANIZATION) {
									dispatch(updateCertificateForOrganization(data))
								}

								resolve(data)
							})
							.catch((err) => {
								const {
									response: { status },
									response: { data },
								} = err
								const errors = utils.take(data, 'errors.errors') || []
								const isKonturError =
									status === 400 && utils.take(data, 'errorCode') === 'kontur_api'

								dispatch(
									addServerError({
										err,
										text: 'Не удалось загрузить заявление',
										details: utils.getDetailsFromError(err),
									})
								)

								reject({
									errors: isKonturError ? errors : [],
								})
							})
					},
				})
			)
		})
	}

export const deleteCertificateForm = (id, type) => (dispatch, getState) => {
	if (!type) {
		return Promise.reject()
	}

	return api[type.toLowerCase()]
		.deleteCertificateForm(id)
		.then(() => {
			const subject =
				type.toUpperCase() === PARTICIPANTS_TYPES.PERSON
					? getState().person.single.data
					: getState().organization.single.data
			const konturInfo = utils.take(subject, 'konturInfo')

			if (!konturInfo) {
				return
			}

			if (type.toUpperCase() === PARTICIPANTS_TYPES.PERSON) {
				dispatch(deleteCertificateFormForPerson())
			} else if (type.toUpperCase() === PARTICIPANTS_TYPES.ORGANIZATION) {
				dispatch(deleteCertificateFormForOrganization())
			}
		})
		.catch((err) => {
			const {
				response: { status },
				response: { data },
			} = err
			const errors = utils.take(data, 'errors.errors') || []
			const isKonturError = status === 400 && utils.take(data, 'errorCode') === 'kontur_api'

			dispatch(
				addServerError({
					err,
					text: 'Не удалось удалить заявление',
					details: utils.getDetailsFromError(err),
				})
			)

			throw {
				errors: isKonturError ? errors : [],
			}
		})
}

export const downloadReleaseCertStatement = () => (dispatch, getState) => {
	const documents = getState().document.files.data.filter(
		(e) => e.categoryId === RELEASE_CERT_STATEMENT
	)
	let file = documents[0]

	if (documents.length !== 1) {
		// take last file by date
		file = [...documents].sort(
			(a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
		)[0]
	}

	downloadFile(
		{
			url: file.url,
			onError: () => {
				dispatch(
					addServerError({
						text: 'Ошибка при загрузке файла',
					})
				)
				dispatch(hideNotice(file.id))
			},
			onDownloadProgress: (e) => dispatch(addDownloadFileStatus(e, file.id, file.title)),
		},
		file.title
	)
}
