import { resetApplicationList } from 'actions/application'
import { resetBrokerList } from 'actions/broker'
import { resetDeliveryList } from 'actions/delivery'
import { resetDepositList } from 'actions/deposit'
import { resetEntrepreneurList } from 'actions/entrepreneur'
import { resetFacilityList } from 'actions/facility'
import { resetLeadList } from 'actions/lead'
import { resetPagination, resetSort } from 'actions/listParameters'
import { resetLoanList } from 'actions/loan'
import { resetOrganizationList } from 'actions/organization'
import { resetPersonList } from 'actions/person'
import { resetPipeline } from 'actions/pipeline'
import { resetRosreestrRegistration } from 'actions/rosreestrRegistration'
import { resetSearch } from 'actions/search'
import TAB_PATHS from 'const/tabPaths'

export const resetsByLocation =
	(prevPath = null) =>
	(dispatch, getState) => {
		const location = getState().router.location.pathname.slice(1).split('/')[0]

		// lead
		if (shouldReset(prevPath, location, TAB_PATHS.lead)) {
			dispatch(resetLeadList())

			dispatch(resetSort())
			dispatch(resetPagination())
			dispatch(resetPipeline())
			dispatch(resetSearch())
		}

		// broker
		if (shouldReset(prevPath, location, TAB_PATHS.brokers)) {
			dispatch(resetBrokerList())

			dispatch(resetSort())
			dispatch(resetPagination())
			dispatch(resetSearch())
		}

		// application
		if (shouldReset(prevPath, location, TAB_PATHS.application)) {
			dispatch(resetApplicationList())

			dispatch(resetPagination())
			dispatch(resetSort())
			dispatch(resetPipeline())
			dispatch(resetSearch())
		}

		// loan
		if (shouldReset(prevPath, location, TAB_PATHS.loan)) {
			dispatch(resetLoanList())

			dispatch(resetPagination())
			dispatch(resetSort())
			dispatch(resetPipeline())
			dispatch(resetSearch())
		}

		// facility
		if (shouldReset(prevPath, location, TAB_PATHS.facility)) {
			dispatch(resetFacilityList())

			dispatch(resetPagination())
			dispatch(resetSort())
			dispatch(resetSearch())
		}

		// participants
		if (shouldReset(prevPath, location, TAB_PATHS.participants)) {
			dispatch(resetPersonList())
			dispatch(resetEntrepreneurList())
			dispatch(resetOrganizationList())

			dispatch(resetPagination())
			dispatch(resetSort())
			dispatch(resetSearch())
		}

		// assessment
		if (shouldReset(prevPath, location, TAB_PATHS.assessment)) {
			dispatch(resetPagination())
			dispatch(resetSort())
			dispatch(resetSearch())
		}

		// calendar
		if (shouldReset(prevPath, location, TAB_PATHS.calendar)) {
			dispatch(resetPagination())
			dispatch(resetSort())
			dispatch(resetSearch())
		}

		// deposits
		if (shouldReset(prevPath, location, TAB_PATHS.deposits)) {
			dispatch(resetDepositList())
		}

		// deliveries
		if (shouldReset(prevPath, location, TAB_PATHS.deliveries)) {
			dispatch(resetDeliveryList())

			dispatch(resetPipeline())
			dispatch(resetPagination())
			dispatch(resetSort())
			dispatch(resetSearch())
		}

		// rosreestr-registrations
		if (shouldReset(prevPath, location, TAB_PATHS.rosreestrRegistrations)) {
			dispatch(resetRosreestrRegistration())

			dispatch(resetPagination())
			dispatch(resetSort())
			dispatch(resetSearch())
		}

		// calculator
		if (shouldReset(prevPath, location, TAB_PATHS.calculator)) {
			dispatch(resetPagination())
			dispatch(resetSort())
			dispatch(resetSearch())
		}
	}

function shouldReset(prevPath, currentLocation, paths) {
	return paths.includes(prevPath) && !paths.includes(currentLocation)
}
