import { setEditOrganizationForm } from 'actions/broker/forms/organization'
import {
	fetchCategories,
	fetchFiles,
	resetDocumentCategories,
	resetDocumentFiles,
} from 'actions/document'
import { resetHistory } from 'actions/history'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import HISTORY from 'const/history'
import { OWNER_TYPES } from 'const/ownerTypes'
import { actions } from 'reducers/broker/single'

import { utils } from 'helpers'

import { formatEditOrganizationForm } from './helpers'

export const singleResetOrganization = () => (dispatch) => {
	dispatch(actions.reset())
	dispatch(resetDocumentFiles())
	dispatch(resetDocumentCategories())
	dispatch(resetHistory())
}

export const editOrganizationForm = () => (dispatch, getState) => {
	const { data } = getState().broker.single
	const comment = getState().history.data.find((item) => item.type === HISTORY.TYPES.COMMENT.value)

	const form = formatEditOrganizationForm({ ...data, comment })
	dispatch(setEditOrganizationForm(form))
}

export const resolveBrokerOrganizationStatus = (data) => async (dispatch, getState) => {
	const { state, rejectionReason } = data
	const broker = getState().broker.single

	try {
		await api.broker.update(
			{ state, rejectionReason: state === 'REJECTED' ? rejectionReason : null },
			broker.data.id
		)

		dispatch(
			actions.setData({
				...broker.data,
				state,
				rejectionReason: state === 'REJECTED' ? rejectionReason : null,
			})
		)
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось обновить организацию',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}

export const getOrganizationBrokers = (organizationId) => async (dispatch) => {
	try {
		const { data } = await api.broker.searchBrokerEmployees({ organizationId })
		const borkersAccGroup = await Promise.all([
			...data.map((el) => api.broker.getEmployeesAccesGroupById(el.id)),
		])
		const brokerAllAccGroupOption = await api.broker.getEmployeesAccesGroup()

		const res = data.map((broker, index) => {
			return {
				...broker,
				role: borkersAccGroup[index].data.map((accGroup) => accGroup),
			}
		})

		dispatch(actions.setAccessGroup(brokerAllAccGroupOption.data))
		dispatch(actions.setBrokers(res))
	} catch (error) {
		//
	}
}

export const getOrganization = (id) => (dispatch) => {
	dispatch(actions.setFetchingStatus(true))

	return api.broker
		.get(id)
		.then(({ data }) => {
			dispatch(getOrganizationBrokers(id))

			// Получаем и записываем документы с категориями
			dispatch(fetchCategories([OWNER_TYPES.BROKER]))
			dispatch(fetchFiles(id))

			dispatch(actions.setData(data))
			dispatch(actions.setFetchingStatus(false))

			return data
		})
		.catch((error) => {
			dispatch(actions.setFetchingStatus(false))

			dispatch(
				addServerError({
					text: 'Не удалось загрузить информацию организации',
					details: utils.getDetailsFromError(error),
				})
			)
		})
}

export const updateAccesGroup =
	(allValuesIds, currValuesIds, employeesId) => async (dispatch, getState) => {
		const { id } = getState().broker.single.data

		try {
			await Promise.all([
				...allValuesIds.map((accGroupId) =>
					currValuesIds.includes(accGroupId)
						? api.broker.createAccesGroup(employeesId, accGroupId)
						: api.broker.delAccesGroup(employeesId, accGroupId)
				),
			])
			dispatch(getOrganizationBrokers(id))
		} catch (err) {
			//
		}
	}
