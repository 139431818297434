import { check } from './check'
import { checkResult } from './checkResult'
import { condition } from './condition'
import { main } from './main'
import { policy } from './policy'
import { product } from './product'
import { program } from './program'
import { relation } from './relation'
import { requiredAction } from './requiredAction'
import { additionalRequest } from './additionalRequest'
import { titlePart } from './titlePart'

export const creditPolicyReport = {
	main,
	product,
	program,
	relation,
	check,
	condition,
	requiredAction,
	additionalRequest,
	policy,
	titlePart,
	checkResult,
}

export namespace CreditPolicyReport {
	export type Main = ReturnType<typeof main>
	export type Product = ReturnType<typeof product>
	export type Program = ReturnType<typeof program>
	export type CheckResult = ReturnType<typeof checkResult>
	export type AdditionalRequest = ReturnType<typeof additionalRequest>
	export type Relation = ReturnType<typeof relation>
	export type Policy = ReturnType<typeof policy>
	export type Condition = ReturnType<typeof condition>
	export type Check = ReturnType<typeof check>
	export type TitlePart = ReturnType<typeof titlePart>
	export type RequiredAction = ReturnType<typeof requiredAction>
}
